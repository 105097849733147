import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material'
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers'
import {grey} from '@mui/material/colors'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {EditableEmployeeOffer} from 'src/entities/EmployeeOffer'
import SvgIconStyle from 'src/components/SvgIconStyle'
import {observer} from 'mobx-react-lite'
import {useRootStore} from 'src/stores/UseStore'

interface AddOrEditBonusProps {
  setEmployeeOfferList: React.Dispatch<
    React.SetStateAction<EditableEmployeeOffer[]>
  >
  employeeOffer: EditableEmployeeOffer
  onDelete: (val: number) => void
  isRefund: boolean
}

const AddOrEditBonus = observer(
  ({
    employeeOffer,
    setEmployeeOfferList,
    onDelete,
    isRefund
  }: AddOrEditBonusProps) => {
    const {filterStore} = useRootStore()
    const theme = useTheme()

    return (
      <Card
        elevation={3}
        sx={{
          marginTop: 2,
          border: `1px solid ${grey[500]}`
        }}
      >
        <CardContent>
          <Stack
            pb={2}
            direction="row"
            justifyContent="space-between"
            spacing={1}
          >
            <TextField
              helperText={employeeOffer.amountValidation}
              error={!!employeeOffer.amountValidation}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₪</InputAdornment>
                )
              }}
              label="Amount"
              value={employeeOffer.totalAmount || ''}
              sx={{width: 220}}
              name="totalAmount"
              variant="outlined"
              type="number"
              onChange={event => {
                setEmployeeOfferList(prev =>
                  prev.map(p =>
                    p.id === employeeOffer.id
                      ? {
                          ...p,
                          totalAmount: Number(event.target.value),
                          amountValidation: ''
                        }
                      : p
                  )
                )
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                views={['year', 'month', 'day']}
                value={employeeOffer.dateGivenFor || null}
                label="Date"
                onChange={newValue => {
                  setEmployeeOfferList(prev =>
                    prev.map(p =>
                      p.id === employeeOffer.id
                        ? {
                            ...p,
                            dateGivenFor: newValue
                              ? new Date(newValue)
                              : new Date(),
                            dateValidation: ''
                          }
                        : p
                    )
                  )
                }}
                shouldDisableDate={(date: Date) =>
                  filterStore.month
                    ? date.getFullYear() !== filterStore.month.getFullYear() ||
                      date.getMonth() !== filterStore.month.getMonth()
                    : false
                }
                inputFormat="dd/MM/yyyy"
                renderInput={params => (
                  <TextField
                    name="fromDate"
                    sx={{width: 400}}
                    {...params}
                    helperText={employeeOffer.dateValidation}
                  />
                )}
              />
            </LocalizationProvider>
            <TextField
              fullWidth
              helperText={!isRefund && employeeOffer.noteValidation}
              error={!isRefund && !!employeeOffer.noteValidation}
              label="Note"
              name="note"
              variant="outlined"
              value={employeeOffer?.note}
              onChange={event => {
                setEmployeeOfferList(prev =>
                  prev.map(p =>
                    p.id === employeeOffer.id
                      ? {
                          ...p,
                          note: event.target.value,
                          noteValidation: ''
                        }
                      : p
                  )
                )
              }}
            />
            <IconButton
              onClick={() => {
                onDelete(employeeOffer.id)
              }}
            >
              <SvgIconStyle src="/assets/icons/ic_trash.svg" />
            </IconButton>
          </Stack>
          {!isRefund && (
            <Stack width={190}>
              <Stack
                width={190}
                sx={{
                  border: employeeOffer.isPremiumValidation
                    ? `2px solid ${theme.palette.error.main}`
                    : 'none',
                  justifyContent: 'center',
                  borderRadius: 1
                }}
              >
                <RadioGroup
                  defaultValue={employeeOffer?.isPremium}
                  onChange={event => {
                    setEmployeeOfferList(prev =>
                      prev.map(p =>
                        p.id === employeeOffer.id
                          ? {
                              ...p,
                              isPremium:
                                event.currentTarget.value === 'false'
                                  ? false
                                  : true,
                              isPremiumValidation: ''
                            }
                          : p
                      )
                    )
                  }}
                  name="isPremium"
                >
                  <Stack direction="row">
                    <Tooltip
                      title={
                        'Additional payment for specific project where employee met the conditions of the offer, as defined in advance.'
                      }
                      arrow
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio sx={{paddingLeft: 2}} />}
                        label="Permiya"
                      />
                    </Tooltip>
                    <Tooltip
                      title={
                        'One-time unconditional grant as recognition of employee, at discretion of manager.'
                      }
                      arrow
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Bonus"
                      />
                    </Tooltip>
                  </Stack>
                </RadioGroup>
              </Stack>
              {employeeOffer.isPremiumValidation && (
                <Typography
                  textAlign="center"
                  variant="caption"
                  sx={{pt: 1, color: theme.palette.error.main}}
                >
                  No radio button is selected
                </Typography>
              )}
            </Stack>
          )}
        </CardContent>
      </Card>
    )
  }
)

export default AddOrEditBonus
