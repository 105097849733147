import React, {useCallback, useEffect, useState} from 'react'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack
} from '@mui/material'
import AccessTimeSharpIcon from '@mui/icons-material/AccessTimeSharp'
import BeachAccessSharpIcon from '@mui/icons-material/BeachAccessSharp'
import {format} from 'date-fns'
import CuiCancelSaveButton from 'src/components/custom/CuiCancelSaveButton'
import CuiSectionHeader from 'src/components/custom/CuiSectionHeader'
import Department from 'src/entities/Department'
import {useAuth} from 'src/contexts/Auth'
import config from 'src/config'
import Formula from 'src/entities/Formula'
import Offer, {
  BonusTypeId,
  getOfferValidation,
  isEmployeesToOffer,
  validateOffer,
  validateOfferOnSave
} from 'src/entities/Offer'
import DepartmentEmployeesTable from 'src/components/offer/DepartmentEmployeesTable'
import OfferModalFields, {
  EditableOffer
} from 'src/components/offer/OfferModalFields'
import Employee from 'src/entities/Employee'
import EmployeeOffer from 'src/entities/EmployeeOffer'
import {formatDate} from 'src/utils/formatTime'
import EmployeeOfferUpdated from 'src/entities/EmployeeOfferUpdated'
import EmployeeOffersHours from 'src/entities/EmployeeOffersHours'
import {BonusMonthStatus} from 'src/entities/BonusMonth'
import SvgIconStyle from 'src/components/SvgIconStyle'
import Label from 'src/components/Label'
import {groupBy} from 'src/utils/arrayHelper'
import {observer} from 'mobx-react-lite'
import {useRootStore} from 'src/stores/UseStore'

export enum Period {
  Amount = 'Amount',
  Formula = 'Formula'
}

interface OfferModalProps {
  selectedEmployees: EmployeeOffersHours[]
  onCloseModal: () => void
  offerId?: number
  onCloseAdd?: (
    bonusesToAdd: EmployeeOffer[],
    offersToAdd: Offer,
    employeeId?: number
  ) => void
  onCloseEdit?: (bonuses: EmployeeOfferUpdated, offersToAdd: Offer) => void
  offers?: Offer[]
  employees: EmployeeOffersHours[]
  monthStatus?: BonusMonthStatus | undefined
}

const OfferModal = observer(
  ({
    selectedEmployees,
    onCloseModal,
    offerId,
    onCloseAdd,
    onCloseEdit,
    employees
  }: OfferModalProps) => {
    const [offer, setOffer] = useState<EditableOffer>({
      bonusTypeId: BonusTypeId.Overtime
    } as EditableOffer)
    const [formulasList, setFormulasList] = useState<Formula[]>()
    const [checkedBenefit, setCheckedBenefit] = useState({
      isFormulaChecked: false,
      isHoursChecked: false,
      isPizzaChecked: false
    })

    const [periodValue, setPeriodValue] = useState<Period>()

    const [departmentEmployeesList, setDepartmentEmployeesList] = useState<
      Department[]
    >([])
    const [isSaving, setIsSaving] = useState(false)
    const [isIncludeManager, setIsIncludeManager] = useState(false)
    const {fetchWithUser} = useAuth()

    const {filterStore} = useRootStore()

    const isEdit = !!offerId
    const onChangeOffer = (value: Date | string | null, name: string) => {
      setOffer(prev => {
        const newOffer = {
          ...prev,
          [name]: value,
          formulaSelectedValidation:
            name === 'otHoursInterval' || name === 'totalAmount'
              ? ''
              : prev.formulaSelectedValidation,
          otHoursIntervalValidation:
            name === 'otHoursInterval' ? '' : prev.otHoursIntervalValidation,
          totalAmountValidation:
            name === 'totalAmount' ? '' : prev.totalAmountValidation,
          totalPizzaHoursValidation:
            name === 'totalPizzaHours' ? '' : prev.totalPizzaHoursValidation,
          daysValidation: name === 'days' ? '' : prev.daysValidation,
          daysTotalAmountValidation:
            name === 'totalAmount' ? '' : prev.daysTotalAmountValidation
        }
        return {
          ...newOffer,
          ...getOfferValidation(newOffer)
        }
      })
    }

    const onOfferTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const bonusType =
        parseInt(event.currentTarget.value, 10) === BonusTypeId.Overtime
          ? BonusTypeId.Overtime
          : parseInt(event.currentTarget.value, 10) === BonusTypeId.NotTakeOff
          ? BonusTypeId.NotTakeOff
          : BonusTypeId.PeriodHours
      setOffer({
        bonusTypeId: bonusType
      } as EditableOffer)
      setCheckedBenefit({
        isFormulaChecked: false,
        isHoursChecked: false,
        isPizzaChecked: false
      })
      setPeriodValue(undefined)
      checkEmployeeDates(offer.fromDate, offer.toDate, bonusType)
    }

    const removeSelectedEmployees = (employees: Employee[], id: number) => {
      setOffer(prev => ({
        ...prev,
        departments: prev.departments?.map(d => {
          return {
            ...d,
            employees:
              d.id === id
                ? d.employees?.filter(e =>
                    employees.find(em => em.id === e.id && em.locked === false)
                  )
                : d.employees
          }
        })
      }))
    }

    const onCancelClick = () => {
      onCloseModal()
    }

    const onSaveClick = () => {
      const choosePeriodOption =
        offer.bonusTypeId === BonusTypeId.PeriodHours &&
        periodValue === undefined
      const checkFormulaSelected =
        offer.bonusTypeId === BonusTypeId.Overtime &&
        !offer.totalAmount &&
        !offer.otHoursInterval &&
        !offer.formulaId
      const errorEmployees =
        !isIncludeManager &&
        !isEmployeesToOffer(offer.departments || [], employees)
      const errorHours =
        ((offer.bonusTypeId === BonusTypeId.Overtime &&
          checkedBenefit.isHoursChecked === true) ||
          (offer.bonusTypeId === BonusTypeId.PeriodHours && !!Period)) &&
        !offer.otHoursInterval
      const errorTotalAmount =
        ((offer.bonusTypeId === BonusTypeId.Overtime &&
          checkedBenefit.isHoursChecked === true) ||
          (offer.bonusTypeId === BonusTypeId.PeriodHours &&
            periodValue === Period.Amount)) &&
        !offer.totalAmount

      const errorPizza =
        offer.bonusTypeId === BonusTypeId.Overtime &&
        checkedBenefit.isPizzaChecked &&
        !offer.totalPizzaHours
      const errorFormula =
        ((offer.bonusTypeId === BonusTypeId.Overtime &&
          checkedBenefit.isFormulaChecked === true) ||
          (offer.bonusTypeId === BonusTypeId.PeriodHours &&
            periodValue === Period.Formula)) &&
        !offer.formulaId
      const errorDays =
        offer.bonusTypeId === BonusTypeId.NotTakeOff && !offer.days
      const errorTotalAmountDays =
        offer.bonusTypeId === BonusTypeId.NotTakeOff && !offer.totalAmount

      if (
        !validateOffer(offer) ||
        errorEmployees ||
        checkFormulaSelected ||
        errorHours ||
        errorTotalAmount ||
        errorPizza ||
        errorFormula ||
        errorDays ||
        errorTotalAmountDays ||
        choosePeriodOption
      ) {
        setOffer(prev => ({
          ...prev,
          ...validateOfferOnSave(
            offer,
            errorEmployees,
            checkFormulaSelected,
            errorHours,
            errorTotalAmount,
            errorPizza,
            errorFormula,
            errorDays,
            errorTotalAmountDays,
            choosePeriodOption
          )
        }))
      } else {
        setIsSaving(true)
        var formatedOffer = {
          ...offer,
          fromDate: formatDate(offer.fromDate),
          toDate: formatDate(offer.toDate)
        }
        fetchWithUser(
          config.apiUrl +
            `/offers/${format(
              filterStore.month,
              'MM,dd,yyyy'
            )}/${isIncludeManager}`,
          {
            method: isEdit ? 'PUT' : 'POST',
            body: JSON.stringify(formatedOffer),
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
          .then(res => res.json())
          .then((data: any) => {
            isEdit
              ? onCloseEdit &&
                onCloseEdit(data, {
                  ...offer,
                  formula: formulasList?.find(f => f.id === offer.formulaId)
                })
              : onCloseAdd &&
                onCloseAdd(data, {
                  ...offer,
                  formula: formulasList?.find(f => f.id === offer.formulaId)
                })

            onCloseModal()
          })
          .catch(e => {
            setIsSaving(false)
            console.log(e)
          })
      }
    }

    const setNewDep = (dp: Department, employees: Employee[]) => {
      const newDep = {
        id: dp.id,
        name: dp.name,
        employees: employees
      }
      setOffer(prev => ({
        ...prev,
        departments: prev.departments
          ? [...prev.departments, newDep]
          : [newDep],
        employeesValidation: ''
      }))
    }

    const getNotLockedEmployees = (id: number) => {
      return departmentEmployeesList
        ?.find(d => d.id === id)
        ?.employees.filter(e => !e.locked)
    }

    const onCheckEmployee = (dp: Department, employee: Employee) => {
      if (!offer.departments?.find(d => d.id === dp.id)) {
        setNewDep(dp, [employee])
      } else {
        setOffer(prev => ({
          ...prev,
          employeesValidation: '',
          departments: prev.departments?.map(of => {
            if (of.id === dp.id) {
              return {
                ...of,
                employees: of.employees.find(e => e.id === employee.id)
                  ? of.employees.filter(e => e.id !== employee.id)
                  : [...of.employees, employee]
              }
            }
            return of
          })
        }))
      }
    }

    useEffect(() => {
      let employeesByDepartments = groupBy(
        selectedEmployees.filter(se => !se.isManager),
        (e: EmployeeOffersHours) => e.currentDepartment.name
      )
      let departments = Object.values(employeesByDepartments).map(
        ebd =>
          ({
            id: ebd[0].currentDepartment.id,
            name: ebd[0].currentDepartment.name,
            employees: ebd.map(
              e =>
                ({
                  id: e.id,
                  firstName: e.firstName,
                  lastName: e.lastName
                } as Employee)
            )
          } as Department)
      )
      setOffer({
        bonusTypeId: BonusTypeId.Overtime,
        departments: departments
      } as EditableOffer)
      setIsIncludeManager(!!selectedEmployees.find(se => se.isManager))
    }, [selectedEmployees])

    const onChangeDepartmentCheck = (
      e: React.ChangeEvent<HTMLInputElement>,
      dp: Department
    ) => {
      if (!offer.departments?.find(d => d.id === dp.id)) {
        setNewDep(dp, getNotLockedEmployees(dp.id) || [])
      } else {
        if (
          getNotLockedEmployees(dp.id)?.every(e =>
            offer.departments
              ?.find(d => d.id === dp.id)
              ?.employees.find(em => em.id === e.id)
          )
        )
          e.target.checked = false
        setOffer(prev => ({
          ...prev,
          employeesValidation: '',
          departments: prev.departments?.map(of => {
            if (of.id === dp.id) {
              return {
                ...of,
                employees: e.target.checked
                  ? getNotLockedEmployees(dp.id) || []
                  : []
              }
            }
            return of
          })
        }))
      }
    }
    const checkEmployeeDates = useCallback(
      (fromDateEdited: Date, toDateEdited: Date, bonusType: BonusTypeId) => {
        if (fromDateEdited && toDateEdited) {
          fromDateEdited = new Date(fromDateEdited)
          toDateEdited = new Date(toDateEdited)
          fetchWithUser(
            config.apiUrl +
              `/offers/GetOverlappingEmployeesId/${format(
                fromDateEdited,
                'MM,dd,yyyy'
              )}/${format(toDateEdited, 'MM,dd,yyyy')}/${bonusType}/${offerId}`
          )
            .then(res => res.json())
            .then((ids: number[]) => {
              setDepartmentEmployeesList(prev =>
                prev.map(d => {
                  const employees = d.employees.map(e => {
                    return {...e, locked: ids.includes(e.id) ? true : false}
                  })
                  removeSelectedEmployees(employees, d.id)
                  return {
                    ...d,
                    employees: employees
                  }
                })
              )
            })
        }
      },
      [fetchWithUser, offerId]
    )

    useEffect(() => {
      fetchWithUser(config.apiUrl + '/formulas')
        .then(res => res.json())
        .then((data: Formula[]) => {
          setFormulasList(data)
        })
    }, [fetchWithUser])
    useEffect(() => {
      if (offerId) {
        Promise.all([
          fetchWithUser(
            config.apiUrl +
              `/departments/getByManagerIdWithEmployees/${format(
                filterStore.month,
                'MM,dd,yyyy'
              )}`
          ),

          fetchWithUser(config.apiUrl + `/offers/${offerId}`)
        ])
          .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
          .then(([data1, data2]: [Department[], EditableOffer]) => {
            setDepartmentEmployeesList(data1)
            if (data2.employeeOffers?.some(eo => eo.isManager)) {
              setIsIncludeManager(true)
            }
            setOffer({
              ...data2,
              fromDate: new Date(data2.fromDate),
              toDate: new Date(data2.toDate)
            })
            checkEmployeeDates(data2.fromDate, data2.toDate, data2.bonusTypeId)
            setCheckedBenefit({
              isFormulaChecked: !!data2.formulaId,
              isHoursChecked: !!data2.totalAmount,
              isPizzaChecked: !!data2.totalPizzaHours
            })
            setPeriodValue(
              data2.formulaId
                ? Period.Formula
                : data2.totalAmount
                ? Period.Amount
                : undefined
            )
          })
      } else {
        fetchWithUser(
          config.apiUrl +
            `/departments/getByManagerIdWithEmployees/${format(
              filterStore.month,
              'MM,dd,yyyy'
            )}`
        )
          .then(res => res.json())
          .then((data: Department[]) => {
            setDepartmentEmployeesList(data)
          })
      }
    }, [
      fetchWithUser,
      filterStore.month,
      checkEmployeeDates,
      offerId,
      filterStore
    ])

    return (
      <Dialog fullWidth={true} maxWidth="lg" open>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row">
              {filterStore.monthStatus === BonusMonthStatus.Locked
                ? 'View Offer'
                : isEdit
                ? 'Edit Offer'
                : 'Add Offer'}
              {isEdit ? (
                offer.bonusTypeId === BonusTypeId.Overtime ? (
                  <Label
                    sx={{ml: 1}}
                    color="primary"
                    startIcon={<AccessTimeSharpIcon color="primary" />}
                  >
                    {BonusTypeId[BonusTypeId.Overtime]}
                  </Label>
                ) : offer.bonusTypeId === BonusTypeId.NotTakeOff ? (
                  <Label
                    sx={{ml: 1}}
                    color="warning"
                    startIcon={<BeachAccessSharpIcon color="warning" />}
                  >
                    {BonusTypeId[BonusTypeId.NotTakeOff]}
                  </Label>
                ) : (
                  <Label
                    sx={{ml: 1}}
                    color="info"
                    startIcon={<BeachAccessSharpIcon color="info" />}
                  >
                    {BonusTypeId[BonusTypeId.PeriodHours]}
                  </Label>
                )
              ) : (
                <FormControl
                  sx={{pl: 6}}
                  disabled={filterStore.monthStatus === BonusMonthStatus.Locked}
                >
                  <RadioGroup
                    value={offer.bonusTypeId}
                    defaultValue={BonusTypeId.Overtime}
                    name="radio-buttons-group"
                    row
                    onChange={onOfferTypeChange}
                  >
                    <FormControlLabel
                      value={BonusTypeId.Overtime}
                      control={<Radio />}
                      label={BonusTypeId[BonusTypeId.Overtime]}
                    />
                    <FormControlLabel
                      value={BonusTypeId.PeriodHours}
                      control={<Radio />}
                      label={BonusTypeId[BonusTypeId.PeriodHours]}
                    />
                    <FormControlLabel
                      value={BonusTypeId.NotTakeOff}
                      control={<Radio />}
                      label={BonusTypeId[BonusTypeId.NotTakeOff]}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </Stack>
            <IconButton onClick={onCancelClick}>
              <SvgIconStyle src="/assets/icons/ic_close.svg" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={8} sx={{pt: 6}} direction="row">
            <OfferModalFields
              checkEmployeeDates={checkEmployeeDates}
              offer={offer}
              setOffer={setOffer}
              formulasList={formulasList}
              onChangeOffer={onChangeOffer}
              setCheckedBenefit={setCheckedBenefit}
              checkedBenefit={checkedBenefit}
              periodValue={periodValue}
              setPerioValue={setPeriodValue}
            />
            <Grid xs={6} item>
              <Box sx={{pb: 2.5}}>
                <CuiSectionHeader text="EMPLOYEES" />
              </Box>
              <DepartmentEmployeesTable
                isIncludeManager={isIncludeManager}
                setIsIncludeManager={setIsIncludeManager}
                departmentEmployees={departmentEmployeesList}
                offerEmplyees={
                  offer.departments ? offer.departments : ([] as Department[])
                }
                onCheckEmployee={onCheckEmployee}
                onChangeDepartmentCheck={onChangeDepartmentCheck}
                employeeValidation={offer.employeesValidation}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CuiCancelSaveButton
            loading={isSaving}
            onCancel={onCancelClick}
            onSave={onSaveClick}
            disableSave={
              filterStore.monthStatus === BonusMonthStatus.Locked || isSaving
            }
            saveButtonText={isEdit ? 'Save' : 'Create'}
          />
        </DialogActions>
      </Dialog>
    )
  }
)

export default OfferModal
