import {Stack, Typography} from '@mui/material'
import Offer, {BonusTypeId} from 'src/entities/Offer'
import {getHeaderDate} from 'src/utils/dateHelper'

export default function Header({
  offer,
  checked
}: {
  offer: Offer
  checked: boolean
}) {
  return (
    <Stack justifyContent="center" alignItems="center" p={2}>
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={offer.bonusTypeId === BonusTypeId.Overtime ? 1 : 0}
        direction={
          offer.bonusTypeId === BonusTypeId.Overtime ? 'row' : 'column'
        }
      >
        <Typography variant="subtitle2">
          {BonusTypeId[offer.bonusTypeId]}
        </Typography>
        <Typography variant="subtitle2">{getHeaderDate(offer)}</Typography>
      </Stack>
      {checked && (
        <Stack justifyContent="center" alignItems="center" color="grey.600">
          {offer.bonusTypeId === BonusTypeId.NotTakeOff ? (
            <Typography>
              ₪{offer.totalAmount} for less than {offer.days}d
            </Typography>
          ) : (
            <>
              <Typography>
                {' '}
                {offer.formula && `${offer.formula?.percent}%`}
                {offer.bonusTypeId === BonusTypeId.PeriodHours && offer.formula
                  ? ` / ${offer.otHoursInterval}h`
                  : ''}{' '}
                {offer.formula && offer.totalAmount && '+'}{' '}
                {offer.totalAmount &&
                  `₪${offer.totalAmount} / ${offer.otHoursInterval}h`}
              </Typography>
              {offer.totalPizzaHours && (
                <Typography>+Pizza / {offer.totalPizzaHours}h</Typography>
              )}
            </>
          )}
        </Stack>
      )}
    </Stack>
  )
}

export const HeaderName = (offer: Offer) => {
  return offer.bonusTypeId === BonusTypeId.GoodJob
    ? 'Good Job'
    : offer.bonusTypeId === BonusTypeId.Refund
    ? 'Refund'
    : `${getHeaderDate(offer)}\n${
        offer.bonusTypeId === BonusTypeId.NotTakeOff
          ? `₪${offer.totalAmount} for\ntaking less than ${offer.days}d`
          : `${offer.formula ? `${offer.formula?.percent}%` : ''}${
              offer.bonusTypeId === BonusTypeId.PeriodHours && offer.formula
                ? ` / ${offer.otHoursInterval}h`
                : ''
            }${offer.formula && offer.totalAmount ? ' +' : ''} ${
              offer.totalAmount
                ? `₪${offer.totalAmount} / ${offer.otHoursInterval}h`
                : ''
            }${
              offer.totalPizzaHours
                ? `\n+ Pizza / ${offer.totalPizzaHours}h`
                : ''
            }`
      }`
}
