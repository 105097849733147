import {useState, useEffect} from 'react'
import {Stack, Typography, Card, CardContent, CardActions} from '@mui/material'
import {getLocalDateFromUtc} from 'src/utils/formatTime'
import SvgIconStyle from 'src/components/SvgIconStyle'
import {format} from 'date-fns'
import {useAuth} from 'src/contexts/Auth'
import config from 'src/config'
import {downloadFileByBase64} from 'src/utils/DownloadFile'
import FileData from 'src/entities/FileData'
import CuiProgressButton from 'src/components/custom/CuiProgressButton'

export default function OrganizationTree() {
  const [updateTime, setUpdateTime] = useState<Date>()
  const [loadingRefresh, setLoadingRefresh] = useState(false)
  const [downloading, setDownloading] = useState(false)

  const {fetchWithUser} = useAuth()

  useEffect(() => {
    fetchWithUser(
      config.apiUrl + '/OrganizationTree/GetOrganizationTreeUpdatedDate'
    )
      .then(res => res.json())
      .then((data: Date) => {
        if (data) setUpdateTime(getLocalDateFromUtc(data))
      })
  }, [fetchWithUser])

  const exportOrganizationTree = () => {
    setDownloading(true)
    fetchWithUser(config.apiUrl + '/OrganizationTree/GetOrganizationTree')
      .then(res => res.json())
      .then((data: FileData) => {
        downloadFileByBase64(data.content, data.name)
        setDownloading(false)
      })
  }

  const importOrganizationTree = () => {
    setLoadingRefresh(true)
    fetchWithUser(config.apiUrl + '/OrganizationTree/ImportOrganizationTree')
      .then(res => res.json())
      .then((data: Date) => {
        if (data) {
          setUpdateTime(getLocalDateFromUtc(data))
          setLoadingRefresh(false)
        }
      })
  }

  return (
    <Stack px={5} spacing={4}>
      <Stack direction="row" alignItems="baseline" spacing={3}>
        <Typography fontWeight="bold" fontSize="x-large">
          Organization Tree
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography color="text.disabled">
            {' '}
            Last update from Harmony:{' '}
            {updateTime && format(updateTime, 'dd/MM/yyyy p')}
          </Typography>
        </Stack>
      </Stack>

      <Card sx={{width: 560, height: 366}}>
        <CardContent>
          <Stack alignItems="center" spacing={5}>
            <img
              src="/assets/icons/ic_organization_tree.svg"
              alt="organizationTree"
            />
            <Typography sx={{textAlign: 'center'}} color="GrayText">
              To synchronize last changes from Harmony, click Refresh.
            </Typography>
          </Stack>
        </CardContent>
        <CardActions sx={{justifyContent: 'center'}}>
          <CuiProgressButton
            sx={{width: 100}}
            color="inherit"
            onClick={importOrganizationTree}
            startIcon={<SvgIconStyle src="/assets/icons/ic_refresh.svg" />}
            loading={loadingRefresh}
            variant="outlined"
          >
            Refresh
          </CuiProgressButton>

          <CuiProgressButton
            sx={{height: 36, width: 100}}
            variant="outlined"
            color="inherit"
            onClick={exportOrganizationTree}
            startIcon={<SvgIconStyle src="/assets/icons/ic_download.svg" />}
            size="small"
            loading={downloading}
          >
            Export
          </CuiProgressButton>
        </CardActions>
      </Card>
    </Stack>
  )
}
