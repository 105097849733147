import {Avatar} from '@mui/material'
import {useAuth} from 'src/contexts/Auth'
// ----------------------------------------------------------------------

export default function AccountPopover() {
  const {user} = useAuth()

  return (
    <Avatar sx={{fontSize: '0.875rem'}}>
      {user?.givenName?.charAt(0)}
      {user?.surname?.charAt(0)}
    </Avatar>
  )
}
