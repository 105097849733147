import {ReactNode} from 'react'
import {Stack} from '@mui/material'

interface CuiMainContainerProps {
  children: ReactNode
}

export default function CuiMainContainer({children}: CuiMainContainerProps) {
  return (
    <Stack py={6} px={16} spacing={3}>
      {children}
    </Stack>
  )
}
