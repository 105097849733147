import {Stack, IconButton, Card, CardContent} from '@mui/material'
import AccessTimeSharpIcon from '@mui/icons-material/AccessTimeSharp'
import BeachAccessSharpIcon from '@mui/icons-material/BeachAccessSharp'
import {format} from 'date-fns'
import Label, {LabelColor} from 'src/components/Label'
import SvgIconStyle from 'src/components/SvgIconStyle'
import CuiIcon from 'src/components/custom/CuiIcon'
import CuiTitleContent from 'src/components/custom/CuiTitleContent'
import Offer, {BonusTypeId} from 'src/entities/Offer'
import Department from 'src/entities/Department'
import {getLocalDateFromUtc} from 'src/utils/formatTime'

interface ExistingOfferCardProps {
  offer: Offer
  departments: Department[]
  setExistingOffer: (offer: Offer) => void
}

export default function ExistingOfferCard({
  offer,
  departments,
  setExistingOffer
}: ExistingOfferCardProps) {
  return (
    <Card elevation={3}>
      <CardContent>
        <Stack direction="row" p={1} justifyContent="space-between">
          <Stack direction="row" spacing={6}>
            {offer.bonusTypeId === BonusTypeId.Overtime ? (
              <>
                <CuiIcon color="primary">
                  <AccessTimeSharpIcon color="primary" />
                </CuiIcon>
                <CuiTitleContent
                  title="TYPE"
                  content={BonusTypeId[offer.bonusTypeId]}
                  width={90}
                />
                <CuiTitleContent
                  title="PERCENT"
                  content={
                    offer.formula?.percent ? `${offer.formula?.percent}%` : '-'
                  }
                  width={100}
                />
                <CuiTitleContent
                  title="FIXED"
                  content={
                    offer.totalAmount && offer.otHoursInterval
                      ? `₪${offer.totalAmount} for every ${offer.otHoursInterval}h`
                      : '-'
                  }
                  width={150}
                />
                <CuiTitleContent
                  title="ADDITIONAL"
                  content={
                    offer.totalPizzaHours
                      ? `Pizza for ${offer.totalPizzaHours} hours`
                      : '-'
                  }
                  width={150}
                />
              </>
            ) : (
              <>
                <CuiIcon color="warning">
                  <BeachAccessSharpIcon color="warning" />
                </CuiIcon>
                <CuiTitleContent
                  title="TYPE"
                  content={BonusTypeId[offer.bonusTypeId]}
                  width={240}
                />
                <CuiTitleContent
                  title="FIXED"
                  content={
                    offer.totalAmount && offer.days
                      ? `₪${offer.totalAmount} for taking less than ${offer.days}d`
                      : ''
                  }
                  width={350}
                />
              </>
            )}
            <CuiTitleContent
              title="DATES"
              content={`${format(
                getLocalDateFromUtc(offer.fromDate),
                'dd/MM/yyyy'
              )} - ${format(getLocalDateFromUtc(offer.toDate), 'dd/MM/yyyy')}`}
              width={200}
            />
            <CuiTitleContent
              title="TEAM"
              content={
                <Stack direction="row" spacing={1}>
                  {offer.departments?.map(d => (
                    <Label
                      key={d.name}
                      fontSize="meduim"
                      variant="ghost"
                      color={
                        departments.find(dep => dep.id === d.id)
                          ?.color as LabelColor
                      }
                      margin={5}
                    >
                      {d.name}
                    </Label>
                  ))}
                </Stack>
              }
              width={130}
            />
          </Stack>
          <IconButton
            onClick={() => {
              setExistingOffer(offer)
            }}
          >
            <SvgIconStyle src="/assets/icons/navbar/ic_copy.svg" />
          </IconButton>
        </Stack>
      </CardContent>
    </Card>
  )
}
