import {useEffect, useState} from 'react'
import {Stack, Typography, Button} from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import {format} from 'date-fns'
import {observer} from 'mobx-react-lite'
import CuiMonthPicker from 'src/components/custom/CuiMonthPicker'
import config from 'src/config'
import {useAuth} from 'src/contexts/Auth'
import BonusMonth, {
  BonusMonthStatus,
  ChangeCurrencyData
} from 'src/entities/BonusMonth'
import {compareYearMonth} from 'src/utils/dateHelper'
import ExportReportCard from 'src/components/report/ExportReportCard'
import Department from 'src/entities/Department'
import CuiProgressButton from 'src/components/custom/CuiProgressButton'
import Employee from 'src/entities/Employee'
import SvgIconStyle from 'src/components/SvgIconStyle'
import SendReportModal from 'src/components/report/SendReportModal'
import {useRootStore} from 'src/stores/UseStore'
import DollarRateModal from 'src/components/report/DollarRateModal'

export enum ReportType {
  Bonus,
  Finance,
  Manager
}

const Reports = observer(() => {
  const {filterStore} = useRootStore()
  const [bonusMonthList, setBonusMonthList] = useState<BonusMonth[]>()
  const monthDate = filterStore.month || new Date()
  const [departments, setDepartments] = useState<Department[]>([])
  const [managers, setManagers] = useState<Employee[]>([])
  const [employees, setEmployees] = useState<Employee[]>([])
  const {fetchWithUser} = useAuth()
  const [isSaving, setIsSaving] = useState(false)
  const [openEmailDialog, setOpenEmailDialog] = useState(false)
  const [currencyData, setCurrencyData] = useState<ChangeCurrencyData>()
  const [currentLockMonth, setCurrentLockMonth] = useState<BonusMonth>({
    id: 0,
    month: filterStore.month || new Date(),
    status: BonusMonthStatus.Locked
  })

  const {roleStore} = useRootStore()

  useEffect(() => {
    if (roleStore.isAdmin || roleStore.isSuperManager) {
      fetchWithUser(config.apiUrl + '/admin/SetMeAsAdmin', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
    }
  }, [roleStore, fetchWithUser])

  const findMonthInList = bonusMonthList?.find(b =>
    compareYearMonth(b.month, filterStore.month || new Date())
  )
  const isLocked = findMonthInList?.status === BonusMonthStatus.Locked

  const isEditStatusDisable = () => {
    var lastMonth = new Date()
    lastMonth.setMonth(lastMonth.getMonth() - 1)

    return !(
      compareYearMonth(monthDate, new Date()) ||
      (compareYearMonth(monthDate, lastMonth) &&
        new Date().getDate() <= Number(config.lockDay)) ||
      !isLocked
    )
  }

  const setDollarRate = () => {
    setCurrencyData(undefined)
    fetchWithUser(`${config.apiUrl}/bonusMonth/changeStatusAndUpdateCurrency`, {
      method: 'POST',
      body: JSON.stringify(currentLockMonth),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((res: number) => {
        setIsSaving(false)
        setBonusMonthList(prev =>
          prev?.map(b =>
            b.id === res
              ? {
                  ...b,
                  status: isLocked
                    ? BonusMonthStatus.Open
                    : BonusMonthStatus.Locked
                }
              : b
          )
        )
        setBonusMonthList(prev =>
          prev ? [...prev, currentLockMonth] : [currentLockMonth]
        )
        setCurrentLockMonth(p => ({
          ...p,
          dollarRate: 0,
          comment: ''
        }))
      })
  }

  const lockMonth = () => {
    const bm = findMonthInList || currentLockMonth
    setIsSaving(true)
    fetchWithUser(config.apiUrl + '/bonusMonth/changeStatusOrGetCurrency', {
      method: 'POST',
      body: JSON.stringify(bm),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((res: ChangeCurrencyData) => {
        if (res) {
          if (res.isNeedToChange) {
            setCurrencyData(res)
            setCurrentLockMonth({
              ...bm,
              dollarRate: res.dollarRate,
              comment: ''
            })
          } else {
            setIsSaving(false)
            setBonusMonthList(prev =>
              prev?.map(b =>
                b.id === findMonthInList?.id
                  ? {
                      ...b,
                      status: isLocked
                        ? BonusMonthStatus.Open
                        : BonusMonthStatus.Locked
                    }
                  : b
              )
            )
            let lm = {...currentLockMonth, id: res.bonusMonthId}
            setBonusMonthList(prev => (prev ? [...prev, lm] : [lm]))
          }
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  useEffect(() => {
    fetchWithUser(config.apiUrl + '/bonusMonth')
      .then(res => res.json())
      .then((data: BonusMonth[]) => {
        setBonusMonthList(data)
      })
  }, [fetchWithUser, roleStore])

  useEffect(() => {
    if (filterStore.month && !isNaN(filterStore.month.getTime())) {
      if (roleStore.isAdmin) {
        fetchWithUser(
          config.apiUrl +
            `/departments/getAllDepartments/${format(
              filterStore.month,
              'MM,dd,yyyy'
            )}`
        )
          .then(res => res.json())
          .then((data: Department[]) => {
            setDepartments(
              data.map(d => ({
                ...d,
                color: Math.floor(Math.random() * 16777215).toString(16)
              }))
            )
          })
        fetchWithUser(
          config.apiUrl +
            `/employees/GetAllEmployeesInOffers/${format(
              filterStore.month,
              'MM,dd,yyyy'
            )}`
        )
          .then(res => res.json())
          .then((data: Employee[]) => {
            setEmployees(
              data.map(e => ({
                ...e,
                color: Math.floor(Math.random() * 16777215).toString(16)
              }))
            )
          })
        fetchWithUser(
          config.apiUrl +
            `/departments/GetAllManagers/${format(
              filterStore.month,
              'MM,dd,yyyy'
            )}`
        )
          .then(res => res.json())
          .then((data: Employee[]) => {
            setManagers(
              data.map(e => ({
                ...e,
                color: Math.floor(Math.random() * 16777215).toString(16)
              }))
            )
          })
      } else {
        fetchWithUser(
          config.apiUrl +
            `/departments/${
              roleStore.isSuperManager
                ? 'GetBySuperManagerId'
                : 'GetByManagerId'
            }/${format(filterStore.month, 'MM,dd,yyyy')}`
        )
          .then(res => res.json())
          .then((data: Department[]) => {
            if (data) {
              setDepartments(data)
            }
          })
      }
    }
  }, [filterStore.month, fetchWithUser, roleStore, filterStore])

  return (
    <Stack px={5} spacing={4}>
      <Typography fontWeight="bold" fontSize="x-large">
        Reports
      </Typography>
      {departments.length ? (
        <>
          {roleStore.isAdmin ? (
            <>
              {' '}
              <Stack direction="row" spacing={2}>
                <CuiMonthPicker
                  date={filterStore.month}
                  setDate={(val: Date) => {
                    filterStore.month = val
                  }}
                  bonusMonthList={bonusMonthList}
                  showIcon={false}
                  width={304}
                />
                <CuiProgressButton
                  color={isLocked ? 'error' : 'inherit'}
                  disabled={isEditStatusDisable() || isSaving}
                  variant="outlined"
                  sx={{height: '100%'}}
                  loading={isSaving}
                  onClick={lockMonth}
                >
                  {isLocked ? (
                    <>
                      {' '}
                      <LockOutlinedIcon sx={{mr: 1}} color="error" />
                      Locked
                    </>
                  ) : (
                    'Lock'
                  )}
                </CuiProgressButton>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpenEmailDialog(true)
                  }}
                  color="inherit"
                  startIcon={<SvgIconStyle src="/assets/icons/ic_mail.svg" />}
                >
                  Send report via email
                </Button>
                {openEmailDialog && (
                  <SendReportModal
                    month={filterStore.month}
                    employees={employees}
                    managers={managers}
                    closeModal={() => {
                      setOpenEmailDialog(false)
                    }}
                  />
                )}
              </Stack>
              <Stack direction="row" justifyContent="flex-start" spacing={2}>
                <ExportReportCard
                  type={ReportType.Finance}
                  month={filterStore.month}
                  departments={departments}
                />
                <ExportReportCard
                  type={ReportType.Bonus}
                  month={filterStore.month}
                  departments={departments}
                />
              </Stack>
            </>
          ) : (
            <>
              <Stack direction="row" spacing={2}>
                <CuiMonthPicker
                  date={filterStore.month}
                  setDate={(val: Date) => {
                    filterStore.month = val
                  }}
                  bonusMonthList={bonusMonthList}
                  showIcon={false}
                  width={304}
                />
              </Stack>
              <ExportReportCard
                type={
                  roleStore.isSuperManager
                    ? ReportType.Bonus
                    : ReportType.Manager
                }
                month={filterStore.month}
                departments={departments}
              />
            </>
          )}
        </>
      ) : (
        <div>Loading...</div>
      )}
      {!!currencyData && (
        <DollarRateModal
          currentLockMonth={currentLockMonth}
          setCurrentLockMonth={setCurrentLockMonth}
          currencyData={currencyData}
          onClose={() => {
            setCurrencyData(undefined)
            setIsSaving(false)
          }}
          onOkClick={setDollarRate}
        />
      )}
    </Stack>
  )
})

export default Reports
