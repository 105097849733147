import {EditableOffer} from 'src/components/offer/OfferModalFields'
import Department from 'src/entities/Department'
import Formula from 'src/entities/Formula'
import EmployeeOffer, {BonusStatus} from 'src/entities/EmployeeOffer'
import EmployeeOffersHours from 'src/entities/EmployeeOffersHours'
import Base from 'src/entities/Base'

export default interface Offer extends Base {
  id: number
  bonusTypeId: BonusTypeId
  fromDate: Date
  toDate: Date
  days?: number
  totalAmount?: number
  otHoursInterval?: number
  totalPizzaHours?: number
  formulaId?: number
  formula?: Formula
  note?: string
  isPremium?: boolean
  employeeOffers?: EmployeeOffer[]
  departments?: Department[]
  minTotalBasicMinutesForBonus?: number
  isFromFirstWorkingHour?: boolean
}
export enum BonusTypeId {
  Refund = 1,
  GoodJob,
  NotTakeOff,
  Overtime,
  PeriodHours,
  'Good Job' = GoodJob,
  'Not Taking Off Days' = NotTakeOff,
  'Period Hours' = PeriodHours
}

export const isHoursType = (bonusTypeId: BonusTypeId) => {
  return (
    bonusTypeId === BonusTypeId.Overtime ||
    bonusTypeId === BonusTypeId.PeriodHours
  )
}

export const isDaysType = (bonusTypeId: BonusTypeId) => {
  return bonusTypeId === BonusTypeId.NotTakeOff
}

export const isCalculatedType = (bonusTypeId: BonusTypeId) => {
  return (
    bonusTypeId === BonusTypeId.NotTakeOff ||
    bonusTypeId === BonusTypeId.Overtime ||
    bonusTypeId === BonusTypeId.PeriodHours
  )
}

export function isEmployeesToOffer(
  departments: Department[],
  employees: EmployeeOffersHours[]
) {
  const managerId =
    employees?.length > 0 && employees[0].isManager === true
      ? employees[0].employeeId
      : 0
  var index = false
  departments.forEach(d => {
    if (d.employees.filter(e => e.id !== managerId).length > 0) {
      index = true
      return
    }
  })
  return index
}

export function validateOffer(offer: EditableOffer) {
  return (
    !offer.fromDateValidation &&
    !offer.toDateValidation &&
    offer.fromDate &&
    offer.toDate
  )
}
export function validateDate(offer: Offer, name: string) {
  const date1 = name === 'fromDate' ? offer.fromDate : offer.toDate
  const checkDate = new Date(date1 || new Date())
  if (name === 'fromDate') {
    if (checkDate > new Date(offer.toDate))
      return 'The start date must be smaller than the end date'
  } else {
    if (checkDate < new Date(offer.fromDate))
      return 'The end date must be greater than the start date'
  }
}

export function validateOfferOnSave(
  offer: EditableOffer,
  errorEmployees: boolean,
  checkFormulaSelected: boolean,
  errorHours: any,
  errorTotalAmount: any,
  errorPizza: any,
  errorFormula: any,
  errorDays: any,
  errorTotalAmountDays: any,
  choosePeriodOption: any
) {
  const offerValidation = {
    fromDateValidation: offer.fromDate ? offer.fromDateValidation : 'Required',
    toDateValidation: offer.toDate ? offer.toDateValidation : 'Required',
    employeesValidation: errorEmployees
      ? 'Select employees included in the offer.'
      : undefined,
    formulaSelectedValidation: checkFormulaSelected
      ? 'select at least one formula'
      : undefined,
    otHoursIntervalValidation: errorHours ? 'Required' : undefined,
    totalAmountValidation: errorTotalAmount ? 'Required' : undefined,
    totalPizzaHoursValidation: errorPizza ? 'Required' : undefined,
    formulaIdValidation: errorFormula ? 'Required' : undefined,
    daysValidation: errorDays ? 'Required' : undefined,
    daysTotalAmountValidation: errorTotalAmountDays ? 'Required' : undefined,
    choosePeriodOptionValidation: choosePeriodOption
      ? 'Select at least one option'
      : undefined
  } as EditableOffer
  return offerValidation
}
export function getOfferValidation(offer: Offer) {
  const offerValidation = {
    fromDateValidation: offer.fromDate
      ? validateDate(offer, 'fromDate')
      : undefined,
    toDateValidation: offer.toDate ? validateDate(offer, 'toDate') : undefined
  } as EditableOffer
  return offerValidation
}

export function sortOffers(offersList: Offer[]) {
  return offersList
    .sort(
      (a, b) => a.toDate.setHours(0, 0, 0, 0) - b.toDate.setHours(0, 0, 0, 0)
    )
    .sort(
      (a, b) =>
        a.fromDate.setHours(0, 0, 0, 0) - b.fromDate.setHours(0, 0, 0, 0)
    )
    .sort((a, b) => a.bonusTypeId - b.bonusTypeId)
}

export function CheckOverlapping(bonuses: EmployeeOffer[], offers: Offer[]) {
  let isOverlapping = false
  let managerOffers = [...bonuses]
  let overlapping: Record<string, boolean> = {}
  managerOffers.forEach(eo => {
    if (eo.bonusStatus === BonusStatus.Active) {
      const offer = offers.find(o => o.id === eo.offerId)
      if (offer) {
        for (
          let day = new Date(offer.fromDate);
          day <= offer.toDate;
          day.setDate(day.getDate() + 1)
        ) {
          if (overlapping[`${day.getDate()}:${day.getMonth() + 1}`]) {
            isOverlapping = true
            break
          } else {
            overlapping[`${day.getDate()}:${day.getMonth() + 1}`] = true
          }
        }
      }
    }
    if (isOverlapping) return
  })
  return isOverlapping
}
