import {Dayjs} from 'dayjs'

export default interface ManagerOfferWorkHour {
  offerId: number
  otMinuts: number
  date: Date
  status: Status
  error: boolean
  formtError: boolean
  otTime: Dayjs
}

export enum Status {
  DB = 1,
  Synel
}
