import {ReactNode} from 'react'
import {Stack} from '@mui/material'
import {ColorSchema} from 'src/theme/palette'

interface IconProps {
  children: ReactNode
  color: ColorSchema
}

export default function CuiIcon({children, color}: IconProps) {
  return (
    <Stack
      padding={2}
      sx={{
        backgroundColor: theme => theme.palette[color].lighter
      }}
      justifyContent="center"
      alignItems="center"
      borderRadius={1}
    >
      {children}
    </Stack>
  )
}
