import {useLocation, Navigate} from 'react-router-dom'
import {Typography, Stack, Paper} from '@mui/material'
import {useAuth} from 'src/contexts/Auth'
import LoginButton from 'src/components/auth/LoginButton'

export default function LoginPage() {
  const location = useLocation()
  const {isAuth} = useAuth()

  const {from} = (location.state as any) || {from: {pathname: '/'}}

  // if we have a user, we shouldn't be at this url
  if (isAuth) return <Navigate replace to={from} />

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      height="100%"
      spacing={3}
    >
      <Paper elevation={3}>
        <Stack
          width={400}
          height={620}
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <img src="/assets/Invoice.gif" width="250" alt="login" />
          <Typography component="h1" variant="h4">
            Welcome to Bonus App
          </Typography>
          <LoginButton />
        </Stack>
      </Paper>
    </Stack>
  )
}
