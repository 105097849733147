// components
import SvgIconStyle from '../../SvgIconStyle'

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{width: 1, height: 1}}
  />
)

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  invoice: getIcon('ic_invoice'),
  userGroup: getIcon('ic_user_group')
}

const navConfig = [
  {
    subheader: '',
    items: [
      {title: 'Bonus List', path: '/bonus/list', icon: ICONS.ecommerce},
      {title: 'Reports', path: '/bonus/reports', icon: ICONS.invoice},
      {
        title: 'Organization Tree',
        path: '/bonus/organization',
        icon: ICONS.userGroup
      }
    ]
  }
]

export default navConfig
