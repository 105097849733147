import {useState} from 'react'
import {
  Autocomplete,
  TextField,
  Typography,
  Stack,
  Card,
  CardActions,
  CardContent
} from '@mui/material'
import {format} from 'date-fns'
import {SeoIllustration} from 'src/assets'
import SvgIconStyle from 'src/components/SvgIconStyle'
import CuiAlertDialog from 'src/components/custom/CuiAlertDialog'
import Department from 'src/entities/Department'
import {useAuth} from 'src/contexts/Auth'
import config from 'src/config'
import FileData from 'src/entities/FileData'
import {downloadFileByBase64} from 'src/utils/DownloadFile'
import {ReportType} from 'src/components/report/Report'
import CuiProgressButton from 'src/components/custom/CuiProgressButton'

interface ExportReportCardProps {
  type: ReportType
  month: Date | null
  departments: Department[]
}

export default function ExportReportCard({
  type,
  month,
  departments
}: ExportReportCardProps) {
  const [openExportDepartmentsDialog, setOpenExportDepartmentsDialog] =
    useState(false)
  const [selectedDepartments, setSelectedDepartments] = useState<number[]>([])
  const [loading, setLoading] = useState(false)
  const {fetchWithUser} = useAuth()
  const exportBonus = () => {
    if (month && !isNaN(month.getTime())) {
      setLoading(true)
      fetchWithUser(
        config.apiUrl +
          `/reports/${
            type === ReportType.Finance ? 'finance' : 'bonus'
          }/${format(month, 'MM,dd,yyyy')}`,
        {
          method: 'POST',
          body: JSON.stringify(
            type === ReportType.Bonus
              ? selectedDepartments
              : departments.map(d => d.id)
          ),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(res => res.json())
        .then((data: FileData[]) => {
          let interval = 500
          data.forEach(f => {
            setTimeout(() => {
              downloadFileByBase64(f.content, f.name)
            }, interval)
            interval += 500
          })
          setOpenExportDepartmentsDialog(false)
          setLoading(false)
        })
    }
  }

  return (
    <>
      <Card sx={{width: 304, height: 380}}>
        <CardContent>
          <Stack alignItems="center" spacing={5}>
            <SeoIllustration
              sx={{
                width: 160,
                height: 120,
                margin: {xs: 'auto', md: 'inherit'}
              }}
            />
            <Typography fontSize="14px" fontWeight={600}>
              {ReportType[type]} Report
            </Typography>
            <Typography sx={{textAlign: 'center'}}>
              {type === ReportType.Bonus
                ? 'Bonuses offer and Harmony hours by department'
                : 'Monthly amount of bonuess  for all employees'}
            </Typography>
          </Stack>
        </CardContent>
        <CardActions>
          <Stack width="100%" alignItems="center">
            <CuiProgressButton
              variant="outlined"
              sx={{height: 36}}
              onClick={() => {
                type === ReportType.Bonus
                  ? setOpenExportDepartmentsDialog(true)
                  : exportBonus()
              }}
              loading={loading}
              color="inherit"
              size="small"
              startIcon={<SvgIconStyle src="/assets/icons/ic_download.svg" />}
            >
              Export
            </CuiProgressButton>
          </Stack>
        </CardActions>
      </Card>
      <CuiAlertDialog
        fullWidth={true}
        maxWidth="sm"
        open={openExportDepartmentsDialog}
        close={() => {
          setOpenExportDepartmentsDialog(false)
        }}
        title="Export bonus report"
        okButtonText="Export"
        okClick={exportBonus}
        body={
          <Autocomplete
            sx={{paddingTop: 2}}
            multiple
            limitTags={3}
            options={departments}
            getOptionLabel={d => d.name}
            onChange={(event: any, newValue: Department[]) => {
              setSelectedDepartments(newValue.map(d => d.id))
            }}
            renderInput={params => (
              <TextField {...params} label="Select departments" />
            )}
          />
        }
      />
    </>
  )
}
