import {useState, useEffect} from 'react'
import {Stack, Typography} from '@mui/material'
import {format} from 'date-fns'
import ExistingOfferCard from 'src/components/offer/ExistingOfferCard'
import CuiMainContainer from 'src/components/custom/CuiMainContainer'
import CuiSelectWithOptions from 'src/components/custom/CuiSelectWithOptions'
import CuiMonthPicker from 'src/components/custom/CuiMonthPicker'
import {useAuth} from 'src/contexts/Auth'
import config from 'src/config'
import Department from 'src/entities/Department'
import Offer, {BonusTypeId} from 'src/entities/Offer'
import {getEnumOptionWithLabel} from 'src/utils/enumHelper'
import {getLocalDateFromUtc} from 'src/utils/formatTime'

const colors = ['info', 'success', 'warning', 'error']

export default function ExistingOffersPage() {
  const [offers, setOffers] = useState<Offer[]>([])
  const [departments, setDepartments] = useState<Department[]>([])
  const [type, setType] = useState(0)
  const [department, setDepartment] = useState(0)
  const [month, setMonth] = useState<Date | null>(new Date())
  const [existingOffer, setExistingOffer] = useState<Offer>()
  const {fetchWithUser} = useAuth()

  useEffect(() => {
    if (!month || isNaN(month.getTime())) setMonth(new Date())
    else {
      fetchWithUser(
        config.apiUrl + `/offers/GetByManagerId/${format(month, 'MM,dd,yyyy')}`
      )
        .then(res => res.json())
        .then((data: Offer[]) => {
          const mappedOffers = data.map(t => ({
            ...t,
            fromDate: getLocalDateFromUtc(t.fromDate),
            toDate: getLocalDateFromUtc(t.toDate)
          }))
          setOffers(mappedOffers)
        })

      fetchWithUser(
        config.apiUrl +
          `/departments/GetByManagerId/${format(month, 'MM,dd,yyyy')}`
      )
        .then(res => res.json())
        .then((data: Department[]) => {
          if (data) {
            setDepartments(
              data.map((d, index) => ({...d, color: colors[index]}))
            )
          }
        })
    }
  }, [month, fetchWithUser])

  return (
    <CuiMainContainer>
      <Typography fontWeight="bold" fontSize="x-large">
        Existing Offers
      </Typography>
      <Stack direction="row" spacing={1}>
        <CuiSelectWithOptions
          label="Type"
          options={getEnumOptionWithLabel(BonusTypeId)}
          onChange={event => setType(Number(event.target.value))}
        />
        <CuiSelectWithOptions
          label="Team"
          options={departments.map(d => ({value: d.id, label: d.name}))}
          onChange={event => setDepartment(Number(event.target.value))}
        />
        <CuiMonthPicker date={month} setDate={setMonth} />
      </Stack>
      <Stack spacing={4}>
        {offers
          .filter(
            offer =>
              (type === 0 || type === offer.bonusTypeId) &&
              (!department || offer.departments?.find(d => d.id === department))
          )
          .map(offer => (
            <ExistingOfferCard
              offer={offer}
              key={offer.id}
              departments={departments}
              setExistingOffer={setExistingOffer}
            />
          ))}
      </Stack>

      {existingOffer && <></>}
    </CuiMainContainer>
  )
}
