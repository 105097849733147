import {useState} from 'react'
import {Card, CardContent, IconButton, Stack, Typography} from '@mui/material'
import {format} from 'date-fns'
import {EditableEmployeeOffer} from 'src/entities/EmployeeOffer'
import SvgIconStyle from 'src/components/SvgIconStyle'
import CuiAlertDialog from 'src/components/custom/CuiAlertDialog'
import AddOrEditBonus from 'src/components/bonus/AddOrEditBonus'

interface DisplayBonusProps {
  setEmployeeOfferList: React.Dispatch<
    React.SetStateAction<EditableEmployeeOffer[]>
  >
  employeeOffer: EditableEmployeeOffer
  onDelete: (val: number) => void
  isRefund: boolean
}

export default function DisplayBonus({
  employeeOffer,
  setEmployeeOfferList,
  onDelete,
  isRefund
}: DisplayBonusProps) {
  const [isEdit, setIsEdit] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  return (
    <>
      <CuiAlertDialog
        color="error"
        open={openDeleteDialog}
        close={() => {
          setOpenDeleteDialog(false)
        }}
        title={isRefund ? 'Delete refund?' : 'Delete good job?'}
        okButtonText="Delete"
        okClick={() => {
          onDelete(employeeOffer.id)
        }}
      />
      {isEdit ? (
        <AddOrEditBonus
          employeeOffer={employeeOffer}
          setEmployeeOfferList={setEmployeeOfferList}
          onDelete={() => {
            setOpenDeleteDialog(true)
          }}
          isRefund={isRefund}
        />
      ) : (
        <Card>
          <CardContent>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>₪ {employeeOffer.totalAmount}</Typography>
              <Typography>
                {employeeOffer.dateGivenFor
                  ? format(employeeOffer.dateGivenFor, 'dd/MM/yyyy')
                  : 'Date'}
              </Typography>
              <Typography>{employeeOffer.note || 'Note'}</Typography>
              <Typography>
                {employeeOffer.isPremium === null
                  ? ''
                  : employeeOffer.isPremium
                  ? 'Premiya'
                  : 'Bonus'}
              </Typography>
              <Stack direction="row">
                <IconButton onClick={() => setIsEdit(true)}>
                  <SvgIconStyle src="/assets/icons/ic_edit.svg" />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setOpenDeleteDialog(true)
                  }}
                >
                  <SvgIconStyle src="/assets/icons/ic_trash.svg" />
                </IconButton>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      )}
    </>
  )
}
