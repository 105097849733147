import {Navigate, RouteObject, useRoutes} from 'react-router-dom'
import LoginPage from 'src/components/auth/LoginPage'
import Logout from 'src/components/auth/Logout'
import ProtectedRoute from 'src/components/auth/ProtectedRoutes'
import DashboardLayout from 'src/components/navigation'
import ExistingOffersPage from 'src/components/offer/ExistingOffersPage'
import {observer} from 'mobx-react-lite'
import BonusNavigation from 'src/components/bonus/BonusNavigation'
import AccessDenied from 'src/components/AccessDenied'

export const Router = observer(() => {
  return useRoutes([
    {
      path: '/login',
      element: <LoginPage />
    },
    {
      path: '/logout',
      element: <Logout />
    },
    {
      path: '/accessDenied',
      element: <AccessDenied />
    },
    {
      path: '/offers',
      element: <ExistingOffersPage />
    },
    {
      path: '/accessDenied',
      element: (
        <ProtectedRoute>
          <AccessDenied />
        </ProtectedRoute>
      )
    },
    {
      path: '/',
      element: <Navigate to="/bonus" replace />
    },
    {
      path: '/bonus',
      element: <DashboardLayout />,
      children: [
        {
          element: (
            <ProtectedRoute>
              <BonusNavigation />
            </ProtectedRoute>
          ),
          index: true
        },
        ...['list', 'reports', 'organization'].map(
          p =>
            ({
              path: p,
              element: (
                <ProtectedRoute>
                  <BonusNavigation />
                </ProtectedRoute>
              )
            } as RouteObject)
        )
      ]
    }
  ])
})

export default Router
