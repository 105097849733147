import {useCallback, useEffect, useState} from 'react'
import {Box, Typography} from '@mui/material'
import {
  DataGridPro,
  GridColDef,
  GridRowParams,
  getGridStringOperators,
  GridFilterInputSingleSelect,
  GridFilterItem,
  GridFilterOperator,
  GridValueGetterParams,
  useGridApiRef,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridRowsProp
} from '@mui/x-data-grid-pro'
import Offer, {BonusTypeId} from 'src/entities/Offer'
import ColumnMenu from 'src/components/bonus/ColumnMenu'
import Toolbar from 'src/components/bonus/Toolbar'
import EmployeeOffersHours from 'src/entities/EmployeeOffersHours'
import {
  EmployeeRenderCell,
  OfferRenderCell,
  OfferValueGetter,
  WorkHoursRenderCell
} from 'src/components/bonus/RenderCell'
import Header, {HeaderName} from 'src/components/bonus/Header'
import Department from 'src/entities/Department'
import {
  createTotalRow,
  setTotalAmountHoursMinutes,
  setTotalColumn,
  setTotalWorkHours
} from 'src/components/bonus/CalculateTotalRow'
import Employee from 'src/entities/Employee'
import {RowChangeType} from 'src/components/bonus/BonusPage'
import {BonusMonthStatus} from 'src/entities/BonusMonth'
import {
  displayTimeByHoursAndMinutes,
  displayTimeByMinutes
} from 'src/utils/formatTime'
import OffersManagerModal from 'src/components/offer/OffersManagerModal'
import ManagerRow from 'src/entities/ManagerRow'
import CuiAlertDialog from 'src/components/custom/CuiAlertDialog'
import EmployeeOffer from 'src/entities/EmployeeOffer'
import {getHeaderDate} from 'src/utils/dateHelper'
import ManagerWorkHours from 'src/entities/ManagerWorkHours'
import {useRootStore} from 'src/stores/UseStore'
import {observer} from 'mobx-react-lite'

export enum GridColumn {
  Employee = 'employee',
  Department = 'currentDepartment',
  WorkHours = 'workHours'
}

export const quickSearch: GridFilterOperator = {
  value: 'quickSearch',
  label: 'quickSearch',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.value) {
      return null
    }
    return ({row}) => {
      const r = row as Employee
      const search = (filterItem.value as string).toLowerCase()
      return (
        search.includes(r.id.toString()) &&
        search.includes(r.lastName?.toLowerCase()) &&
        search.includes(r.firstName?.toLowerCase())
      )
    }
  }
}

export const DepartmentsFilter: GridFilterOperator = {
  value: 'contains',
  label: 'contains',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.value) {
      return null
    }
    return params => {
      return params.value?.id === filterItem.value || params.row.isManager
    }
  },
  InputComponent: GridFilterInputSingleSelect
}

const basicColumns: GridColDef[] = [
  {
    field: GridColumn.Employee,
    headerName: 'Employee',
    width: 190,
    headerAlign: 'center',
    type: 'string',
    disableColumnMenu: true,
    filterOperators: [...getGridStringOperators(), quickSearch],
    valueGetter: (params: GridValueGetterParams) =>
      params.row.lastName
        ? params.row.lastName + ' ' + params.row.firstName
        : 'Total',
    sortable: false,
    renderCell: params => EmployeeRenderCell(params)
  },
  {
    field: GridColumn.Department,
    type: 'singleSelect',
    valueOptions: [],
    disableExport: true,
    sortable: false,
    headerName: 'Department',
    hide: true,
    filterOperators: [DepartmentsFilter]
  },
  {
    field: 'workHours',
    headerName: 'Work Hours',
    disableColumnMenu: true,
    sortable: false,
    width: 220,
    headerAlign: 'center',
    align: 'center',
    renderCell: params => WorkHoursRenderCell(params),
    valueGetter: params => displayTimeByMinutes(params.row.totalActualMinutes)
  },
  {
    field: 'total',
    headerName: 'Total Overtime',
    disableColumnMenu: true,
    sortable: false,
    width: 180,
    headerAlign: 'center',
    align: 'center',
    valueGetter: params =>
      params.row.totalOverMinutes != null
        ? displayTimeByHoursAndMinutes(
            params.row.totalOverHours,
            params.row.totalOverMinutes
          )
        : ''
  }
]

const createColumn = (
  offer: Offer,
  checked: boolean,
  setOpenOfferModal: (value: boolean) => void,
  setSelectedManager: (value: ManagerRow) => void
) =>
  ({
    field: offer.id.toString(),
    headerName: HeaderName(offer),
    minWidth:
      offer.bonusTypeId !== BonusTypeId.GoodJob &&
      offer.bonusTypeId !== BonusTypeId.Refund
        ? 200
        : 0,
    sortable: false,
    disableColumnMenu:
      offer.bonusTypeId === BonusTypeId.GoodJob ||
      offer.bonusTypeId === BonusTypeId.Refund,
    renderHeader: () => {
      return offer.bonusTypeId === BonusTypeId.GoodJob ? (
        <Typography variant="subtitle2">Good Job</Typography>
      ) : offer.bonusTypeId === BonusTypeId.Refund ? (
        <Typography variant="subtitle2">Refund</Typography>
      ) : (
        <Header offer={offer} checked={checked} />
      )
    },
    headerAlign: 'center',
    renderCell: params =>
      OfferRenderCell(params, offer, setOpenOfferModal, setSelectedManager),
    valueGetter: params => OfferValueGetter(params, offer),
    align: 'center'
  } as GridColDef)

const customComponents = {
  Toolbar: Toolbar,
  ColumnMenu: ColumnMenu
}

interface BonusTableProps {
  employees: EmployeeOffersHours[]
  offers: Offer[]
  loading: boolean
  openEditModal: (columnId: number) => void
  deleteOffer: (offerId: number) => void
  setSelectedEmployees: (val: any) => void
  departments: Department[]
  newRows: RowChangeType | undefined
  setNewRows: (val: RowChangeType | undefined) => void
  updateManagerOvertime: (offerHours: ManagerWorkHours) => void
  updateManagerNotTakeOff: (managerBonuses: EmployeeOffer[]) => void
  openOfferModal: boolean
  setOpenOfferModal: (val: boolean) => void
  selectedManager: ManagerRow
  setSelectedManager: (val: ManagerRow) => void
  currentOfferId: number
  managers: Employee[] | undefined
  resetManagersEmployees: () => void
  searchEmployees: Employee[] | undefined
}

const BonusTable = observer(
  ({
    employees,
    offers,
    loading,
    openEditModal,
    deleteOffer,
    departments,
    setSelectedEmployees,
    newRows,
    setNewRows,
    updateManagerOvertime,
    updateManagerNotTakeOff,
    openOfferModal,
    setOpenOfferModal,
    selectedManager,
    setSelectedManager,
    currentOfferId,
    managers,
    resetManagersEmployees,
    searchEmployees
  }: BonusTableProps) => {
    const [checked, setChecked] = useState(true)
    const [rows, setRows] = useState<GridRowsProp[]>([])

    const [columns, setColumns] = useState<GridColDef[]>(basicColumns)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [currentOffer, setCurrentOffer] = useState<Offer>()
    const [totalRowPinned, setTotalRowPinned] = useState<GridRowsProp>()
    const apiRef = useGridApiRef()
    const {filterStore} = useRootStore()

    const initialDepartmentsColumn = useCallback(
      (dep: Department[]) => {
        const column = apiRef.current.getColumn(GridColumn.Department)
        apiRef.current.updateColumns([
          {
            ...column,
            valueOptions: dep.map(x => {
              return {value: x.id, label: x.name}
            })
          }
        ])
      },
      [apiRef]
    )

    const setManagerModal = useCallback(() => {
      setSelectedManager({
        offerId: currentOfferId,
        row: employees.filter(e => e.isManager === true)[0]
      } as ManagerRow)
      setOpenOfferModal(true)
    }, [currentOfferId, employees, setOpenOfferModal, setSelectedManager])

    useEffect(() => {
      if (loading) {
        setColumns([...basicColumns])
        setRows([])
        setTotalRowPinned(undefined)
      }
    }, [loading])

    useEffect(() => {
      if (!newRows) return
      let totalRow = createTotalRow(offers, employees.length)
      const data = [
        ...employees.map(employee => {
          setTotalWorkHours(totalRow, employee)
          let totalOverAmount = 0
          let totalOverHours = 0
          let totalOverMinutes = 0
          employee.bonuses?.forEach(bonus => {
            if (bonus.totalAmount !== null) {
              setTotalAmountHoursMinutes(totalRow, bonus)
              if (bonus.totalHours != null) {
                totalOverHours += bonus.totalHours
                totalOverMinutes += bonus.totalMinutes
              }
              totalOverAmount += bonus.totalAmount
            }
          })
          if (totalOverMinutes > 59) {
            totalOverHours++
            totalOverMinutes -= 60
          }
          setTotalColumn(
            totalRow,
            totalOverMinutes,
            totalOverHours,
            totalOverAmount
          )
          return {
            ...employee,
            totalOverAmount: totalOverAmount,
            totalOverHours: totalOverHours,
            totalOverMinutes: totalOverMinutes
          }
        })
      ]
      setTotalRowPinned(totalRow as any as GridRowsProp)
      if (newRows === 'new') {
        setRows(data as any[] as GridRowsProp[])
      } else {
        apiRef.current.updateRows(data)
      }
      if (newRows === 'delete') {
        setOpenDeleteDialog(false)
        currentOffer &&
          apiRef.current.setColumnVisibility(currentOffer.id.toString(), false)
        setCurrentOffer(undefined)
      }
      if (newRows === 'updateDates') {
        setManagerModal()
      }
      setNewRows(undefined)
    }, [
      newRows,
      setNewRows,
      employees,
      offers,
      apiRef,
      currentOffer,
      setManagerModal
    ])

    useEffect(() => {
      apiRef.current.updateColumns(
        offers.map(offer =>
          createColumn(offer, checked, setOpenOfferModal, setSelectedManager)
        )
      )
      apiRef.current.setColumnIndex(GridColumn.WorkHours, 2)
      offers.forEach((o, i) =>
        apiRef.current.setColumnIndex(o.id.toString(), i + 3)
      )
    }, [offers, checked, apiRef, setOpenOfferModal, setSelectedManager])

    useEffect(() => {
      if (currentOfferId) {
        setTimeout(() => {
          apiRef.current.scrollToIndexes({
            colIndex: apiRef.current.getColumnIndex(currentOfferId.toString()),
            rowIndex: 0
          })
          apiRef.current.setColumnHeaderFocus(currentOfferId.toString())
        }, 10)
      }
    }, [currentOfferId, apiRef])

    useEffect(() => {
      initialDepartmentsColumn(departments)
    }, [departments, initialDepartmentsColumn])

    const setEditModal = () => {
      openEditModal(Number(apiRef.current.state.columnMenu.field))
    }

    const openDeleteModal = () => {
      setCurrentOffer(
        offers.find(o => o.id === Number(apiRef.current.state.columnMenu.field))
      )
      setOpenDeleteDialog(true)
    }

    return (
      <Box width="100%" height="70vh" boxShadow={2} borderRadius={1}>
        <CuiAlertDialog
          fullWidth
          color="error"
          open={openDeleteDialog}
          close={() => {
            setOpenDeleteDialog(false)
          }}
          title={`Delete Offer ${
            currentOffer
              ? `"${BonusTypeId[currentOffer.bonusTypeId]} ${getHeaderDate(
                  currentOffer
                )}"`
              : ''
          }?`}
          okButtonText="Delete"
          okClick={() => {
            currentOffer && deleteOffer(currentOffer.id)
          }}
        />
        <DataGridPro
          headerHeight={90}
          rowHeight={72}
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          loading={loading}
          components={customComponents}
          filterModel={filterStore.filterModel}
          onFilterModelChange={newValue => {
            filterStore.filterModel = newValue
          }}
          componentsProps={{
            toolbar: {
              checked: checked,
              setChecked: setChecked,
              loading: loading,
              departments: departments,
              employees: employees
                .filter(e => {
                  let d = filterStore.filterModel.items.find(
                    x => x.columnField === GridColumn.Department
                  )?.value
                  return d ? e.isManager || e.currentDepartment?.id === d : true
                })
                .map(e => `${e.id} - ${e.lastName} ${e.firstName}`),
              managers: managers,
              resetManagersEmployees: resetManagersEmployees,
              searchEmployees: searchEmployees
            },
            columnMenu: {
              openEditModal: setEditModal,
              openDeleteModal: openDeleteModal,
              isLockedMonth: filterStore.monthStatus === BonusMonthStatus.Locked
            }
          }}
          onSelectionModelChange={ids => {
            const selectedIDs = new Set(ids)
            const selectedRowData = employees.filter(e => selectedIDs.has(e.id))
            setSelectedEmployees(selectedRowData)
          }}
          sx={{
            '& .MuiDataGrid-columnHeaderTitleContainerContent': {
              '& .MuiTypography-root': {
                fontSize: '0.875rem'
              }
            },
            '& .MuiDataGrid-cell': {
              fontSize: '0.875rem',
              '& .MuiTypography-root': {
                fontSize: '0.875rem'
              },
              '& .MuiCheckbox-root.Mui-disabled': {
                display: 'none'
              }
            },
            '& .total': {
              backgroundColor: 'grey.200',
              '& .MuiTypography-root': {
                fontWeight: '600'
              },
              '& .MuiDataGrid-cell': {
                fontWeight: '600'
              }
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none'
            }
          }}
          hideFooter
          disableSelectionOnClick
          disableColumnResize
          checkboxSelection
          isRowSelectable={(params: GridRowParams) =>
            !params.row.totalEmployees
          }
          experimentalFeatures={{rowPinning: true}}
          pinnedRows={
            filterStore.filterModel.items.length === 0 && totalRowPinned
              ? {
                  bottom: [totalRowPinned]
                }
              : {}
          }
          pinnedColumns={{
            right: ['total'],
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, GridColumn.Employee]
          }}
          getRowClassName={params => (params.row.id === 0 ? 'total' : '')}
        />
        {openOfferModal && (
          <OffersManagerModal
            offers={offers}
            employee={selectedManager}
            onCloseModal={() => setOpenOfferModal(false)}
            updateManagerOvertime={updateManagerOvertime}
            updateManagerNotTakeOff={updateManagerNotTakeOff}
          />
        )}
      </Box>
    )
  }
)

export default BonusTable
