import {format, getTime, formatDistanceToNow} from 'date-fns'

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy')
}
export function formatDate(date: Date | string | number) {
  return format(new Date(date), 'yyyy-MM-dd')
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p')
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date))
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p')
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  })
}

export function getLocalDateFromUtc(date: Date) {
  return date.toString().endsWith('Z') ? new Date(date) : new Date(date + 'z')
}

export const displayTimeByMinutes = (minutes: number, isShowMinutes = true) => {
  return `${Math.floor(minutes / 60)}${
    isShowMinutes || minutes
      ? `:${minutes % 60 < 10 ? 0 : ''}${minutes % 60}`
      : ''
  }`
}

export const displayModalOverHoursAndMinutes = (
  hours: number,
  minutes: number
) => {
  return `${hours}:${minutes < 10 ? 0 : ''}${minutes}`
}

export const displayTimeByHoursAndMinutes = (
  hours: number,
  minutes: number
) => {
  return `${hours < 10 ? 0 : ''}${hours}:${minutes < 10 ? 0 : ''}${minutes}`
}

export function getFormatTime(hours: number, minutes: number) {
  const time = new Date()
  time.setMinutes(minutes)
  time.setHours(hours)
  return format(time, 'HH:mm')
}

export function daysInMonth(date: Date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
}
