import {observer} from 'mobx-react-lite'
import {useEffect} from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import config from 'src/config'
import {useAuth} from 'src/contexts/Auth'
import {useRootStore} from 'src/stores/UseStore'

const ProtectedRoute = observer(({children}: any) => {
  const {isAuth, isMsalAuth, roles} = useAuth()
  const {roleStore} = useRootStore()
  const location = useLocation()

  useEffect(() => {
    if (isAuth) {
      roleStore.isOrgAdmin =
        roles?.some(r => r === config.roles.orgAdmin) || false
      roleStore.isAdmin = roles?.some(r => r === config.roles.admin) || false
      roleStore.isManager = roles?.some(r => r === config.roles.member) || false
      roleStore.isSuperManager =
        roles?.some(r => r === config.roles.superManager) || false
    }
  }, [roles, roleStore, isAuth])

  if (isAuth) {
    if (
      [roleStore.isManager, roleStore.isAdmin, roleStore.isOrgAdmin].some(
        r => r !== undefined
      )
    )
      return children
    else return <div>Loading...</div>
  }

  if (isMsalAuth) {
    return <div>Loading...</div>
  }

  return <Navigate to="/login" state={{from: location.pathname}} />
})

export default ProtectedRoute
