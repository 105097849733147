import React, {useState} from 'react'
import {
  List,
  Checkbox,
  ListItemButton,
  TextField,
  Box,
  InputAdornment,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import {useTheme} from '@mui/material/styles'
import SearchIcon from '@mui/icons-material/Search'
import Department from 'src/entities/Department'
import Employee from 'src/entities/Employee'
import {InfoIcon} from 'src/theme/overrides/CustomIcons'
import {BonusMonthStatus} from 'src/entities/BonusMonth'
import CuiOverflowTooltip from 'src/components/custom/CuiOverflowTooltip'
import CuiAlertValidation from 'src/components/custom/CuiAlertValidation'
import {observer} from 'mobx-react-lite'
import {useRootStore} from 'src/stores/UseStore'

interface DepartmentEmployeesTableProps {
  departmentEmployees?: Department[]
  isIncludeManager: boolean
  setIsIncludeManager: (val: boolean) => void
  offerEmplyees: Department[]
  onChangeDepartmentCheck: (
    e: React.ChangeEvent<HTMLInputElement>,
    dp: Department
  ) => void
  onCheckEmployee: (dp: Department, employee: Employee) => void
  employeeValidation: string
}

const DepartmentEmployeesTable = observer(
  ({
    departmentEmployees,
    isIncludeManager,
    setIsIncludeManager,
    offerEmplyees,
    onCheckEmployee,
    onChangeDepartmentCheck,
    employeeValidation
  }: DepartmentEmployeesTableProps) => {
    const {filterStore} = useRootStore()
    const theme = useTheme()
    const SUCCESS_LIGHT = theme.palette.success.light
    const [searchText, setSearchText] = useState('')

    const isEmployeeIncluded = (depId: number, empId: number) => {
      return offerEmplyees
        ?.find(d => d.id === depId)
        ?.employees?.find(e => e.id === empId)
    }

    const getHighlightedText = (firstName: string, lastName: string) => {
      // Split text on highlight term, include term itself into parts, ignore case
      var fullName = firstName + ' ' + lastName
      var highlight = searchText
      const parts = fullName.split(new RegExp(`(${highlight})`, 'gi'))
      return (
        <span>
          {parts.map((part, i) => (
            <span
              key={i}
              style={
                part.toLowerCase() === highlight.toLowerCase()
                  ? {fontWeight: 'bold', backgroundColor: SUCCESS_LIGHT}
                  : {}
              }
            >
              {part}
            </span>
          ))}
        </span>
      )
    }

    return (
      <>
        <Box sx={{pb: 3}}>
          <Stack
            direction="row"
            justifyContent="start"
            alignItems="center"
            mb={2}
          >
            <Checkbox
              checked={isIncludeManager}
              onChange={e => {
                setIsIncludeManager(e.target.checked)
              }}
            />
            <Typography sx={{whiteSpace: 'nowrap'}}>
              Include manager in offer
            </Typography>
          </Stack>
          <TextField
            fullWidth
            placeholder="Search..."
            onChange={event => setSearchText(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Box>
        {employeeValidation && (
          <CuiAlertValidation sx={{mb: 3}} errorMessage={employeeValidation} />
        )}

        <Paper
          elevation={3}
          sx={{
            overflow: 'auto',
            maxHeight: 300
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            {departmentEmployees?.map(dp => (
              <List
                sx={{
                  flexGrow: 1,
                  minWidth: '33%',
                  fontSize: '0.875rem',
                  '&:first-of-type': {
                    ml: 1,
                    '& div:first-of-type': {
                      borderTopLeftRadius: theme => theme.shape.borderRadius,
                      borderBottomLeftRadius: theme => theme.shape.borderRadius
                    }
                  },
                  '&:last-of-type': {
                    mr: 1,
                    '& div:first-of-type': {
                      borderTopRightRadius: theme => theme.shape.borderRadius,
                      borderBottomRightRadius: theme => theme.shape.borderRadius
                    }
                  }
                }}
                key={dp.id}
              >
                <ListItemButton
                  disabled={
                    filterStore.monthStatus === BonusMonthStatus.Locked ||
                    dp.employees.every(e => e.locked === true)
                  }
                  sx={{
                    color: 'text.secondary',
                    fontWeight: 600,
                    backgroundColor: theme.palette.grey[200]
                  }}
                  selected={dp.employees.every((e: Employee) =>
                    isEmployeeIncluded(dp.id, e.id)
                  )}
                  key={dp.id}
                >
                  <Checkbox
                    sx={{pl: 0}}
                    checked={dp.employees.every((e: Employee) =>
                      isEmployeeIncluded(dp.id, e.id)
                    )}
                    indeterminate={
                      dp.employees.some((e: Employee) =>
                        isEmployeeIncluded(dp.id, e.id)
                      ) &&
                      !dp.employees.every((e: Employee) =>
                        isEmployeeIncluded(dp.id, e.id)
                      )
                    }
                    onChange={e => {
                      onChangeDepartmentCheck(e, dp)
                    }}
                  />
                  <CuiOverflowTooltip value={dp.name} />
                </ListItemButton>

                {dp.employees.map(e =>
                  e.locked ? (
                    <Tooltip
                      placement="top"
                      title={
                        <span style={{whiteSpace: 'pre-line', margin: 'auto'}}>
                          {`This employee exists on a \n different offer on the same dates`}
                        </span>
                      }
                      key={e.id}
                    >
                      <span>
                        <ListItemButton
                          selected={!!isEmployeeIncluded(dp.id, e.id)}
                          disabled={
                            e.locked ||
                            filterStore.monthStatus === BonusMonthStatus.Locked
                          }
                          onClick={() => {
                            onCheckEmployee(dp, e)
                          }}
                          key={e.id}
                        >
                          <CuiOverflowTooltip
                            value={getHighlightedText(e.firstName, e.lastName)}
                          />

                          <InfoIcon sx={{fontSize: '1rem', ml: '4px'}} />
                        </ListItemButton>
                      </span>
                    </Tooltip>
                  ) : (
                    <ListItemButton
                      selected={!!isEmployeeIncluded(dp.id, e.id)}
                      disabled={
                        e.locked ||
                        filterStore.monthStatus === BonusMonthStatus.Locked
                      }
                      onClick={() => {
                        onCheckEmployee(dp, e)
                      }}
                      key={e.id}
                    >
                      <CuiOverflowTooltip
                        value={getHighlightedText(e.firstName, e.lastName)}
                      />
                    </ListItemButton>
                  )
                )}
              </List>
            ))}
          </Stack>
        </Paper>
      </>
    )
  }
)

export default DepartmentEmployeesTable
