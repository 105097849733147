// scroll bar
import 'simplebar/src/simplebar.css'

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'

import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import {HelmetProvider} from 'react-helmet-async'
// contexts
import {SettingsProvider} from './contexts/SettingsContext'
import {CollapseDrawerProvider} from 'src/contexts/CollapseDrawerContext'
//
import App from './App'
import Store from 'src/stores/RootStore'
import {rootStoreContext} from 'src/stores/UseStore'

// ----------------------------------------------------------------------
const store = new Store()

ReactDOM.render(
  <HelmetProvider>
    <SettingsProvider>
      <CollapseDrawerProvider>
        <rootStoreContext.Provider value={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </rootStoreContext.Provider>
      </CollapseDrawerProvider>
    </SettingsProvider>
  </HelmetProvider>,
  document.getElementById('root')
)
