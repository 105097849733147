export function getUnique(value: any, index: number, self: any[]) {
  return self.findIndex(s => s.id === value.id) === index
}

export function groupBy<T>(arr: T[], fn: (item: T) => any) {
  return arr.reduce<Record<string, T[]>>((prev, curr) => {
    const groupKey = fn(curr)
    const group = prev[groupKey] || []
    group.push(curr)
    return {...prev, [groupKey]: group}
  }, {})
}
