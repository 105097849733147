import {List, ListItem, Checkbox, ListItemButton} from '@mui/material'
import Scrollbar from 'src/components/Scrollbar'

export interface CuiCheckboxListProps {
  options: any[]
  format: (option: any) => any
  selectedOptions: number[]
  setSelectedOptions: (selceted: (prev: number[]) => number[]) => void
}

export default function CuiCheckboxList({
  options,
  format,
  selectedOptions,
  setSelectedOptions
}: CuiCheckboxListProps) {
  const handleToggle = (value: number) => () => {
    selectedOptions.includes(value)
      ? setSelectedOptions(prev => prev.filter(x => x !== value))
      : setSelectedOptions(prev => [...prev, value])
  }

  return (
    <Scrollbar>
      <List>
        {options.map(option => {
          return (
            <ListItem
              key={option.id}
              secondaryAction={
                <Checkbox
                  edge="end"
                  onChange={handleToggle(option.id)}
                  checked={selectedOptions.includes(option.id)}
                />
              }
              disablePadding
            >
              <ListItemButton onClick={handleToggle(option.id)}>
                {format(option)}
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
    </Scrollbar>
  )
}
