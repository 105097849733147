import React from 'react'
import {
  Grid,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  Checkbox,
  Box,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
  FormHelperText,
  Divider,
  Switch,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@mui/material'
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import HelpIcon from '@mui/icons-material/Help'
import CuiSectionHeader from 'src/components/custom/CuiSectionHeader'
import Offer, {BonusTypeId} from 'src/entities/Offer'
import Formula from 'src/entities/Formula'
import {BonusMonthStatus} from 'src/entities/BonusMonth'
import {compareYearMonth} from 'src/utils/dateHelper'
import CuiAlertValidation from 'src/components/custom/CuiAlertValidation'
import {observer} from 'mobx-react-lite'
import {useRootStore} from 'src/stores/UseStore'
import {Period} from './OfferModal'

export interface EditableOffer extends Offer {
  toDateValidation: string
  fromDateValidation: string
  employeesValidation: string
  formulaSelectedValidation: string
  otHoursIntervalValidation: string
  choosePeriodOptionValidation: string
  totalAmountValidation: string
  totalPizzaHoursValidation: string
  formulaIdValidation: string
  daysValidation: string
  daysTotalAmountValidation: string
}

interface OfferModalFieldsProps {
  offer: EditableOffer
  setOffer: (
    offer: EditableOffer | ((prev: EditableOffer) => EditableOffer)
  ) => void
  formulasList: Formula[] | undefined
  checkEmployeeDates: (
    fromDate: Date,
    toDate: Date,
    bonusType: BonusTypeId
  ) => void
  onChangeOffer: (value: Date | string | null, name: string) => void
  checkedBenefit: any
  setCheckedBenefit: (value: any | ((prev: any) => any)) => void
  periodValue: Period | undefined
  setPerioValue: (value: Period | undefined) => void
}

const OfferModalFields = observer(
  ({
    offer,
    setOffer,
    formulasList,
    checkEmployeeDates,
    onChangeOffer,
    checkedBenefit,
    setCheckedBenefit,
    periodValue,
    setPerioValue
  }: OfferModalFieldsProps) => {
    const {filterStore, roleStore} = useRootStore()

    const disableFromDates = (date: Date) => {
      const filterD = filterStore.month || new Date()
      return date > filterD && !compareYearMonth(date, filterD)
    }
    const disableToDates = (date: Date) => {
      const filterD = filterStore.month || new Date()
      return date < filterD && !compareYearMonth(date, filterD)
    }

    const onFormulaChange = (event: SelectChangeEvent) => {
      setOffer(prev => ({
        ...prev,
        formulaId: parseInt(event.target.value),
        formulaSelectedValidation: '',
        formulaIdValidation: '',
        choosePeriodOptionValidation: ''
      }))
    }

    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeOffer(e.target.value, e.target.name)
    }

    console.log('ppp0', periodValue)

    const onDateFieldChange = (name: string, date: Date | null) => {
      onChangeOffer(date ?? null, name)
      if (name === 'toDate') {
        checkEmployeeDates(
          offer.fromDate,
          date || new Date(),
          offer.bonusTypeId
        )
      } else {
        checkEmployeeDates(date || new Date(), offer.toDate, offer.bonusTypeId)
      }
    }
    const onCheckFormula = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCheckedBenefit((prev: any) => ({
        ...prev,
        isFormulaChecked: e.target.checked
      }))
      if (!e.target.checked)
        setOffer(prev => ({
          ...prev,
          formulaId: undefined
        }))
      else {
        setOffer(prev => ({
          ...prev,
          formulaIdValidation: '',
          formulaSelectedValidation: ''
        }))
      }
    }
    const onCheckHours = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCheckedBenefit((prev: any) => ({
        ...prev,
        isHoursChecked: e.target.checked
      }))
      if (!e.target.checked)
        setOffer(prev => ({
          ...prev,
          otHoursInterval: undefined,
          totalAmount: undefined
        }))
      else {
        setOffer(prev => ({
          ...prev,
          totalAmountValidation: '',
          otHoursIntervalValidation: '',
          formulaSelectedValidation: ''
        }))
      }
    }

    const onCheckPizza = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCheckedBenefit((prev: any) => ({
        ...prev,
        isPizzaChecked: e.target.checked
      }))
      if (!e.target.checked)
        setOffer(prev => ({...prev, totalPizzaHours: undefined}))
    }

    const onChangePeriod = (value: Period) => {
      setOffer(prev => ({
        ...prev,
        otHoursInterval: undefined,
        totalAmount: undefined,
        formulaId: undefined,
        formulaSelectedValidation: '',
        choosePeriodOptionValidation: '',
        otHoursIntervalValidation: '',
        totalAmountValidation: ''
      }))
      setPerioValue(value)
    }

    return (
      <Grid item xs={6}>
        <Stack spacing={2.5}>
          <CuiSectionHeader text="DATES" />
          <Stack
            direction="row"
            spacing={2}
            sx={{'& .MuiTypography-root': {mt: '13px'}}}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled={filterStore.monthStatus === BonusMonthStatus.Locked}
                value={offer.fromDate || null}
                label="Start"
                inputFormat="dd/MM/yyyy"
                onChange={newValue => {
                  onDateFieldChange('fromDate', newValue)
                }}
                shouldDisableDate={disableFromDates}
                renderInput={params => (
                  <TextField
                    onKeyDown={e => {
                      e.preventDefault()
                    }}
                    sx={{width: '100%'}}
                    name="fromDate"
                    {...params}
                    helperText={offer.fromDateValidation}
                    error={!!offer.fromDateValidation}
                  />
                )}
              />
            </LocalizationProvider>

            <Typography>-</Typography>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled={filterStore.monthStatus === BonusMonthStatus.Locked}
                value={offer.toDate || null}
                onChange={newValue => {
                  onDateFieldChange('toDate', newValue)
                }}
                shouldDisableDate={disableToDates}
                label="End"
                inputFormat="dd/MM/yyyy"
                renderInput={params => (
                  <TextField
                    onKeyDown={e => {
                      e.preventDefault()
                    }}
                    sx={{width: '100%'}}
                    name="toDate"
                    {...params}
                    helperText={offer.toDateValidation}
                    error={!!offer.toDateValidation}
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
          <Stack direction="row" alignItems="center">
            {' '}
            <CuiSectionHeader text="BENEFIT" />
            <Tooltip title="Choose offer components">
              <HelpIcon sx={{fontSize: '1.0rem', ml: '4px'}} />
            </Tooltip>
          </Stack>

          {offer.formulaSelectedValidation && (
            <CuiAlertValidation
              errorMessage={offer.formulaSelectedValidation}
            />
          )}
          {offer.choosePeriodOptionValidation && (
            <CuiAlertValidation
              errorMessage={offer.choosePeriodOptionValidation}
            />
          )}
          {offer.bonusTypeId === BonusTypeId.NotTakeOff ? (
            <Stack direction="row" spacing={2} alignItems="center">
              <TextField
                disabled={filterStore.monthStatus === BonusMonthStatus.Locked}
                onChange={onTextFieldChange}
                name="totalAmount"
                value={offer.totalAmount}
                type="number"
                sx={{width: '15ch'}}
                variant="outlined"
                helperText={offer.daysTotalAmountValidation}
                error={!!offer.daysTotalAmountValidation}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₪</InputAdornment>
                  )
                }}
              />
              <Typography sx={{whiteSpace: 'nowrap'}}>
                For taking less than
              </Typography>
              <TextField
                disabled={filterStore.monthStatus === BonusMonthStatus.Locked}
                onChange={onTextFieldChange}
                type="number"
                value={offer.days}
                name="days"
                sx={{width: '15ch'}}
                variant="outlined"
                helperText={offer.daysValidation}
                error={!!offer.daysValidation}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">#</InputAdornment>
                  )
                }}
              />
              <Typography>Days</Typography>
            </Stack>
          ) : offer.bonusTypeId === BonusTypeId.PeriodHours ? (
            <RadioGroup
              onChange={e => {
                onChangePeriod(e.target.value as Period)
              }}
            >
              <Stack spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Radio
                    disabled={
                      filterStore.monthStatus === BonusMonthStatus.Locked
                    }
                    value={Period.Amount}
                    checked={periodValue === Period.Amount}
                  />
                  <TextField
                    disabled={
                      !(periodValue === Period.Amount) ||
                      filterStore.monthStatus === BonusMonthStatus.Locked
                    }
                    onChange={onTextFieldChange}
                    name="totalAmount"
                    value={offer.totalAmount || ''}
                    type="number"
                    sx={{width: '10ch'}}
                    variant="outlined"
                    helperText={
                      periodValue === Period.Amount
                        ? offer.totalAmountValidation
                        : ''
                    }
                    error={
                      periodValue === Period.Amount &&
                      !!offer.totalAmountValidation
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₪</InputAdornment>
                      )
                    }}
                  />
                  <Typography sx={{whiteSpace: 'nowrap'}}>
                    Per hour, for working at least
                  </Typography>
                  <TextField
                    disabled={
                      !(periodValue === Period.Amount) ||
                      filterStore.monthStatus === BonusMonthStatus.Locked
                    }
                    onChange={onTextFieldChange}
                    type="number"
                    value={
                      periodValue === Period.Amount ? offer.otHoursInterval : ''
                    }
                    name="otHoursInterval"
                    sx={{width: '8ch'}}
                    variant="outlined"
                    helperText={
                      periodValue === Period.Amount
                        ? offer.otHoursIntervalValidation
                        : ''
                    }
                    error={
                      periodValue === Period.Amount &&
                      !!offer.otHoursIntervalValidation
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">#</InputAdornment>
                      )
                    }}
                  />
                  <Typography>Hours</Typography>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center" mt={2}>
                  <Radio
                    value={Period.Formula}
                    disabled={
                      filterStore.monthStatus === BonusMonthStatus.Locked
                    }
                    checked={periodValue === Period.Formula}
                  />
                  <Stack width="10ch">
                    <FormControl
                      sx={{width: '10'}}
                      error={!!offer.formulaIdValidation}
                    >
                      <Select
                        sx={{
                          '& .MuiSelect-select': {
                            ml: '4px'
                          }
                        }}
                        disabled={
                          !(periodValue === Period.Formula) ||
                          filterStore.monthStatus === BonusMonthStatus.Locked
                        }
                        value={offer?.formulaId?.toString() || ''}
                        onChange={onFormulaChange}
                        startAdornment="%"
                      >
                        {formulasList?.map(f => (
                          <MenuItem key={f.id} value={f.id}>
                            {f.percent}
                          </MenuItem>
                        ))}
                      </Select>
                      {periodValue === Period.Formula && (
                        <FormHelperText>
                          {offer.formulaIdValidation}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                  <Typography sx={{whiteSpace: 'nowrap'}}>
                    Per hour, for working at least
                  </Typography>
                  <TextField
                    disabled={
                      !(periodValue === Period.Formula) ||
                      filterStore.monthStatus === BonusMonthStatus.Locked
                    }
                    onChange={onTextFieldChange}
                    type="number"
                    value={
                      periodValue === Period.Formula
                        ? offer.otHoursInterval
                        : ''
                    }
                    name="otHoursInterval"
                    sx={{width: '8ch'}}
                    variant="outlined"
                    helperText={
                      periodValue === Period.Formula
                        ? offer.otHoursIntervalValidation
                        : ''
                    }
                    error={
                      periodValue === Period.Formula &&
                      !!offer.otHoursIntervalValidation
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">#</InputAdornment>
                      )
                    }}
                  />
                  <Typography>Hours</Typography>
                </Stack>
              </Stack>
            </RadioGroup>
          ) : (
            <>
              <Stack direction="row" spacing={2} alignItems="center">
                <Checkbox
                  checked={checkedBenefit.isFormulaChecked}
                  onChange={onCheckFormula}
                  disabled={filterStore.monthStatus === BonusMonthStatus.Locked}
                />
                <Box sx={{width: '15ch'}}>
                  <FormControl
                    sx={{width: '15ch'}}
                    error={!!offer.formulaIdValidation}
                  >
                    <Select
                      sx={{
                        '& .MuiSelect-select': {
                          ml: '4px'
                        }
                      }}
                      disabled={
                        !checkedBenefit.isFormulaChecked ||
                        filterStore.monthStatus === BonusMonthStatus.Locked
                      }
                      value={offer?.formulaId?.toString() || ''}
                      onChange={onFormulaChange}
                      startAdornment="%"
                    >
                      {formulasList?.map(f => (
                        <MenuItem key={f.id} value={f.id}>
                          {f.percent}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{offer.formulaIdValidation}</FormHelperText>
                  </FormControl>
                </Box>
                <Typography>Per OT hour</Typography>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Checkbox
                  disabled={filterStore.monthStatus === BonusMonthStatus.Locked}
                  checked={checkedBenefit.isHoursChecked}
                  onChange={onCheckHours}
                />
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₪</InputAdornment>
                    )
                  }}
                  onChange={onTextFieldChange}
                  name="totalAmount"
                  variant="outlined"
                  type="number"
                  sx={{width: '15ch'}}
                  value={offer.totalAmount || ''}
                  helperText={offer.totalAmountValidation}
                  error={!!offer.totalAmountValidation}
                  disabled={
                    !checkedBenefit.isHoursChecked ||
                    filterStore.monthStatus === BonusMonthStatus.Locked
                  }
                />
                <Typography sx={{whiteSpace: 'nowrap'}}>For every</Typography>
                <TextField
                  sx={{width: '15ch'}}
                  onChange={onTextFieldChange}
                  name="otHoursInterval"
                  variant="outlined"
                  value={offer.otHoursInterval || ''}
                  type="number"
                  helperText={offer.otHoursIntervalValidation}
                  error={!!offer.otHoursIntervalValidation}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">#</InputAdornment>
                    )
                  }}
                  disabled={
                    !checkedBenefit.isHoursChecked ||
                    filterStore.monthStatus === BonusMonthStatus.Locked
                  }
                />
                <Typography>Hours</Typography>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Checkbox
                  checked={checkedBenefit.isPizzaChecked}
                  onChange={onCheckPizza}
                  disabled={filterStore.monthStatus === BonusMonthStatus.Locked}
                />
                <Typography>Add Pizza for</Typography>
                <TextField
                  sx={{width: '15ch'}}
                  name="totalPizzaHours"
                  variant="outlined"
                  onChange={onTextFieldChange}
                  disabled={
                    !checkedBenefit.isPizzaChecked ||
                    filterStore.monthStatus === BonusMonthStatus.Locked
                  }
                  helperText={offer.totalPizzaHoursValidation}
                  error={!!offer.totalPizzaHoursValidation}
                  value={offer.totalPizzaHours || ''}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">#</InputAdornment>
                    )
                  }}
                />
                <Typography>Hours</Typography>
              </Stack>
              {roleStore.isAdmin && (
                <Stack direction="column" spacing={1}>
                  <Divider variant="middle" />
                  <FormControlLabel
                    checked={!!offer.isFromFirstWorkingHour}
                    onChange={(e, c) => {
                      setOffer(prev => ({...prev, isFromFirstWorkingHour: c}))
                    }}
                    control={<Switch />}
                    label="From first working hour"
                  />
                </Stack>
              )}
            </>
          )}
        </Stack>
      </Grid>
    )
  }
)

export default OfferModalFields
