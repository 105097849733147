import React, {useRef, useEffect, useState} from 'react'
import Tooltip from '@mui/material/Tooltip'
import {Typography} from '@mui/material'

interface CuiOverflowTooltipProps {
  value: string | JSX.Element
  title?: string
}

export default function CuiOverflowTooltip({
  value,
  title
}: CuiOverflowTooltipProps) {
  const [hoverStatus, setHover] = useState(false)
  const textElementRef = useRef<HTMLDivElement>(null)

  const compareSize = () => {
    if (textElementRef.current !== null) {
      const compare =
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth
      setHover(compare)
    }
  }

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize()
    window.addEventListener('resize', compareSize)
  }, [])

  // remove resize listener again on "componentWillUnmount"
  useEffect(
    () => () => {
      window.removeEventListener('resize', compareSize)
    },
    []
  )

  return title || value ? (
    <Tooltip title={title || value} disableHoverListener={!hoverStatus}>
      <Typography
        ref={textElementRef}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        {value}
      </Typography>
    </Tooltip>
  ) : (
    <div>{value}</div>
  )
}
