import {observer} from 'mobx-react-lite'
import {Navigate, useLocation} from 'react-router-dom'
import {useAuth} from 'src/contexts/Auth'
import {useRootStore} from 'src/stores/UseStore'
import OrganizationTree from '../OrganizationTree'
import Reports from '../report/Report'
import BonusListPage from './BonusPage'

const BonusNavigation = observer(() => {
  const {roles} = useAuth()
  const {roleStore} = useRootStore()
  const location = useLocation()

  if (roles) {
    switch (location.pathname) {
      case '/bonus/list': {
        if (roleStore.isHasAccessToBonusListAndReport) return <BonusListPage />
        break
      }
      case '/bonus/reports': {
        if (roleStore.isHasAccessToBonusListAndReport) return <Reports />
        break
      }
      case '/bonus/organization': {
        if (roleStore.isOrgAdmin) return <OrganizationTree />
        break
      }
      case '/':
      case '/bonus': {
        if (roleStore.isHasAccessToBonusListAndReport)
          return <Navigate to="/bonus/list" replace />
        if (roleStore.isOrgAdmin)
          return <Navigate to="/bonus/organization" replace />
        break
      }
    }
  }
  return <Navigate to="/accessDenied" replace />
})

export default BonusNavigation
