export function getEnumOptionWithLabel(type: any) {
  return Object.keys(type).reduce((prev, dpType) => {
    const resNumber = Number(dpType)
    if (!isNaN(resNumber) && resNumber) {
      prev.push({
        value: resNumber as any,
        label: type[resNumber]
      })
    }
    return prev
  }, [] as {value: any; label: string}[])
}
