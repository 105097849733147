// @mui
import {styled} from '@mui/material/styles'
import {Box, Stack, AppBar, Toolbar, Button} from '@mui/material'
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop'
// utils
import cssStyles from '../../../utils/cssStyles'
// config
import {HEADER, NAVBAR} from '../../../config'
import AccountPopover from './AccountPopover'
import LogoutIcon from '@mui/icons-material/Logout'
import {useAuth} from 'src/contexts/Auth'
// ----------------------------------------------------------------------

type RootStyleProps = {
  isCollapse: boolean
  isOffset: boolean
  verticalLayout: boolean
}

const RootStyle = styled(AppBar, {
  shouldForwardProp: prop =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout'
})<RootStyleProps>(({isCollapse, isOffset, verticalLayout, theme}) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default
    })
  }
}))

// ----------------------------------------------------------------------

type Props = {
  onOpenSidebar: VoidFunction
  isCollapse?: boolean
  verticalLayout?: boolean
}

export default function DashboardHeader({
  isCollapse = false,
  verticalLayout = false
}: Props) {
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout
  const {logout} = useAuth()

  const onLogoutClick = () => {
    logout()
  }

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
    >
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: {lg: 5}
        }}
      >
        {/* {isDesktop && verticalLayout }

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )} */}

        <Box sx={{flexGrow: 1}} />

        <Stack direction="row" alignItems="center" spacing={{xs: 0.5, sm: 1.5}}>
          <Stack direction="row" color="text.primary">
            <Button
              variant="text"
              color="inherit"
              onClick={onLogoutClick}
              startIcon={<LogoutIcon />}
            >
              Logout
            </Button>
          </Stack>
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  )
}
