import {Avatar, Stack, Tooltip, Typography} from '@mui/material'
import {GridRenderCellParams} from '@mui/x-data-grid-pro'
import InfoIcon from '@mui/icons-material/Info'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import TextsmsRoundedIcon from '@mui/icons-material/TextsmsRounded'
import Label from 'src/components/Label'
import EmployeeOffer, {BonusStatus} from 'src/entities/EmployeeOffer'
import Offer, {BonusTypeId, isHoursType} from 'src/entities/Offer'
import {
  displayTimeByHoursAndMinutes,
  displayTimeByMinutes
} from 'src/utils/formatTime'
import ManagerRow from 'src/entities/ManagerRow'
import CuiOverflowTooltip from 'src/components/custom/CuiOverflowTooltip'

export function EmployeeRenderCell(params: GridRenderCellParams) {
  return params.row.totalEmployees != null ? (
    <Stack direction="row" justifyContent="space-between" width="50%">
      <Typography>Total</Typography>
      <Typography>{params.row.totalEmployees}</Typography>
    </Stack>
  ) : (
    <Stack direction="row" spacing={2} alignItems="center" height={72} ml={1}>
      <Avatar sx={{bgcolor: `#${params.row.color}`, color: 'white'}}>
        <Typography>
          {params.row.lastName[0]}
          {params.row.firstName[0]}
        </Typography>
      </Avatar>
      <Stack maxWidth={100}>
        <CuiOverflowTooltip
          value={`${params.row.lastName} ${params.row.firstName}`}
        />
        <Typography color="GrayText">
          {params.row.id}
          {params.row.isManager && ' - Manager'}
        </Typography>
      </Stack>
    </Stack>
  )
}

export function WorkHoursRenderCell(params: GridRenderCellParams) {
  return (
    <>
      {params.row.totalEmployees ? (
        <Stack direction="row" width="90%">
          <Stack alignItems="flex-start" width="30%">
            <Typography>
              {displayTimeByMinutes(params.row.totalActualMinutes)}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            width="70%"
            spacing={0.5}
          >
            <Label color="success">
              +{displayTimeByMinutes(params.row.plusMinutes, false)}
            </Label>
            <Label color="error">
              -{displayTimeByMinutes(params.row.minusMinutes, false)}
            </Label>
          </Stack>
        </Stack>
      ) : (
        <Stack direction="row" width="70%">
          <Stack alignItems="flex-start" width="50%">
            <Typography>
              {displayTimeByMinutes(params.row.totalActualMinutes, true)}
            </Typography>
          </Stack>
          {params.row.totalActualMinutes !== params.row.totalBasicMinutes && (
            <Stack alignItems="flex-end" width="50%">
              <Label
                color={
                  params.row.totalActualMinutes < params.row.totalBasicMinutes
                    ? 'error'
                    : 'success'
                }
              >
                {params.row.totalActualMinutes < params.row.totalBasicMinutes
                  ? '-'
                  : '+'}
                {displayTimeByMinutes(
                  params.row.totalActualMinutes < params.row.totalBasicMinutes
                    ? params.row.totalBasicMinutes -
                        params.row.totalActualMinutes
                    : params.row.totalActualMinutes -
                        params.row.totalBasicMinutes,
                  false
                )}
              </Label>
            </Stack>
          )}
        </Stack>
      )}
    </>
  )
}

export function OfferRenderCell(
  params: GridRenderCellParams,
  offer: Offer,
  setOpenOfferModal: (value: boolean) => void,
  setSelectedManager: (value: ManagerRow) => void
) {
  const bs = params.row.bonuses?.filter(
    (b: EmployeeOffer) => b.offerId === offer.id
  )
  if (bs?.length) {
    if (
      offer.bonusTypeId === BonusTypeId.Refund ||
      offer.bonusTypeId === BonusTypeId.GoodJob
    ) {
      let note = bs.reduce((note: string, bonus: EmployeeOffer) => {
        return `${note ? note + ', ' : ''}${bonus.note || ''}`
      }, '')
      return (
        <Stack direction="row" spacing={0.25}>
          <Typography>
            ₪
            {bs.reduce((accumulator: number, bonus: EmployeeOffer) => {
              return accumulator + bonus.totalAmount
            }, 0)}
          </Typography>
          {note && (
            <Tooltip title={note} arrow>
              <TextsmsRoundedIcon htmlColor="lightslategrey" fontSize="small" />
            </Tooltip>
          )}
        </Stack>
      )
    } else {
      let b = bs[0]
      if (params.row.isManager)
        return (
          <div
            style={{
              height: '100%',
              width: '100%',
              margin: 1,
              cursor: 'pointer'
            }}
            onClick={() => {
              setSelectedManager({
                offerId: Number(params.field),
                row: params.row
              } as ManagerRow)
              setOpenOfferModal(true)
            }}
          >
            <Stack
              direction="row"
              color={
                b.bonusStatus === BonusStatus.Overlapping &&
                (b.totalAmount === null ||
                  offer.bonusTypeId === BonusTypeId.NotTakeOff)
                  ? 'warning.darker'
                  : 'grey.500'
              }
              borderRadius={1}
              border={1}
              height="100%"
              width="100%"
              justifyContent="center"
              alignItems="center"
            >
              {b.bonusStatus === BonusStatus.NotActive ? (
                <HighlightOffIcon htmlColor="lightslategrey" fontSize="small" />
              ) : b.bonusStatus === BonusStatus.Overlapping &&
                (b.totalAmount === null ||
                  offer.bonusTypeId === BonusTypeId.NotTakeOff) ? (
                <Typography color="warning.darker">
                  Overlapping Dates
                </Typography>
              ) : (
                <Typography color="black">{formatBonus(offer, b)}</Typography>
              )}
              {b.bonusStatus === BonusStatus.Overlapping && (
                <InfoIcon fontSize="small" color="warning" />
              )}
            </Stack>
          </div>
        )
      else return formatBonus(offer, b)
    }
  }
  return <HighlightOffIcon htmlColor="lightslategrey" fontSize="small" />
}

export function OfferValueGetter(params: GridRenderCellParams, offer: Offer) {
  const b = params.row.bonuses?.find(
    (b: EmployeeOffer) => b.offerId === offer.id
  )
  if (b)
    return offer.bonusTypeId === BonusTypeId.GoodJob ||
      offer.bonusTypeId === BonusTypeId.Refund
      ? `₪${b.totalAmount}`
      : b.totalAmount != null
      ? formatBonus(offer, b)
      : 'Overlapping Dates'
  else return 'x'
}

const formatBonus = (offer: Offer, bonus: EmployeeOffer) => {
  if (isHoursType(offer.bonusTypeId))
    return displayTimeByHoursAndMinutes(bonus.totalHours, bonus.totalMinutes)
  else
    return `${bonus.takeOffDays}${bonus.employeeId ? `/${offer.days}` : ''} d`
}
