import React from 'react'
import {Stack, Box, Tooltip, useTheme} from '@mui/material'
import {BonusTypeId, isDaysType, isHoursType} from 'src/entities/Offer'
import BeachAccessIcon from '@mui/icons-material/BeachAccess'

interface OTHoursBoxProps {
  hours: string
  isHighlight: boolean
  day: number
  isBorder: boolean
  bonusType: BonusTypeId
}

export default function OTHoursBox({
  isHighlight,
  day,
  hours,
  isBorder,
  bonusType
}: OTHoursBoxProps) {
  const theme = useTheme()

  return (
    <>
      <Tooltip
        title={
          isHoursType(bonusType) && isBorder
            ? 'Overlapping offer'
            : isDaysType(bonusType) && hours
            ? 'Day off'
            : ''
        }
        arrow
        disableHoverListener={
          (isDaysType(bonusType) || !isBorder) &&
          (isHoursType(bonusType) || !hours)
        }
      >
        <Stack
          sx={{
            textAlign: 'center',
            flexGrow: 1,
            border: isBorder ? `1px solid ${theme.palette.warning.main}` : 0,
            borderRadius: isBorder ? 1 : 0
          }}
        >
          <Box
            sx={{
              paddingBottom: 0.6,
              paddingTop: 0.6,
              backgroundColor: isHighlight ? 'warning.main_12' : 'grey.200',
              textAlign: 'center',
              fontSize: 'small'
            }}
          >
            {day}
          </Box>
          <Box
            sx={{
              paddingBottom: 0.6,
              paddingTop: 0.6,
              backgroundColor: isHighlight ? 'warning.main_12' : 'white',
              textAlign: 'center',
              fontSize: 'small'
            }}
          >
            {isHoursType(bonusType) ? (
              hours
            ) : hours ? (
              <BeachAccessIcon color="warning" />
            ) : (
              '-'
            )}
          </Box>
        </Stack>
      </Tooltip>
    </>
  )
}
