import Base from 'src/entities/Base'

export default interface BonusMonth extends Base {
  id: number
  month: Date
  status: BonusMonthStatus
  dollarRate?: number
  comment?: string
}
export enum BonusMonthStatus {
  Locked = 1,
  Open
}

export interface ChangeCurrencyData {
  isNeedToChange: boolean
  dollarRate: number
  rateDate: Date
  bonusMonthId: number
}
