import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import SvgIconStyle from 'src/components/SvgIconStyle'

interface CuiListItemIconWithTextProps {
  src: string
  text: string
  onClick?: () => void
  disabled?: boolean
}

export default function CuiListItemIconWithText({
  src,
  text,
  onClick,
  disabled
}: CuiListItemIconWithTextProps) {
  return (
    <ListItem disablePadding>
      <ListItemButton onClick={onClick} disabled={disabled}>
        <ListItemIcon>
          <SvgIconStyle src={src} />
        </ListItemIcon>
        <ListItemText>
          <Typography fontSize="0.875rem">{text}</Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  )
}
