import Offer from 'src/entities/Offer'

export function compareYearMonth(date1: Date, date2: Date) {
  return (
    new Date(date1).getFullYear() === new Date(date2).getFullYear() &&
    new Date(date1).getMonth() === new Date(date2).getMonth()
  )
}

export function getHeaderDate(offer: Offer) {
  return `${offer.fromDate.getDate()}.${
    offer.fromDate.getMonth() + 1
  } - ${offer.toDate.getDate()}.${offer.toDate.getMonth() + 1}`
}
export function compareDates(date1: Date, date2: Date) {
  return (
    new Date(date1).setHours(0, 0, 0, 0) ===
    new Date(date2).setHours(0, 0, 0, 0)
  )
}

export function HasOverlap(start1: Date, end1: Date, start2: Date, end2: Date) {
  return (
    Min(start1, end1) <= Max(start2, end2) &&
    Max(start1, end1) >= Min(start2, end2)
  )
}
export function Max(d1: Date, d2: Date) {
  return d1 > d2 ? d1 : d2
}
export function Min(d1: Date, d2: Date) {
  return d2 > d1 ? d1 : d2
}
