import {GridFilterItem, GridFilterModel} from '@mui/x-data-grid-pro'

const getFilterItem = (
  items: GridFilterItem[],
  columnField: string,
  operatorValue: string = 'is'
) => {
  return items.find(
    c => c.columnField === columnField && c.operatorValue === operatorValue
  )
}

export const changeFilterData = (
  items: GridFilterItem[],
  columnField: string,
  value: any,
  operatorValue: string = 'is'
) => {
  const current = getFilterItem(items, columnField, operatorValue)
  if (operatorValue !== 'is' && (!value || value.length < 1)) {
    return items.filter(item => item !== current)
  }
  if (current) {
    return items.map(item =>
      item === current ? {...item, value: value} : item
    )
  }
  const newFilter = {
    columnField,
    operatorValue,
    value,
    id: items.length + 1
  }
  return [...items, newFilter]
}

export const getFilterModel = (
  filterModel: GridFilterModel,
  columnField: string,
  operatorValue: string = 'is'
) => getFilterItem(filterModel.items, columnField, operatorValue)
