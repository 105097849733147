import {makeAutoObservable} from 'mobx'

export default class RoleStore {
  private _isOrgAdmin?: boolean

  private _isAdmin?: boolean

  private _isManager?: boolean

  private _isSuperManager?: boolean

  constructor() {
    makeAutoObservable(this)
  }

  public get isOrgAdmin(): boolean | undefined {
    return this._isOrgAdmin
  }

  public set isOrgAdmin(value: boolean | undefined) {
    this._isOrgAdmin = value
  }

  public get isAdmin(): boolean | undefined {
    return this._isAdmin
  }

  public set isAdmin(value: boolean | undefined) {
    this._isAdmin = value
  }

  public get isManager(): boolean | undefined {
    return this._isManager
  }

  public set isManager(value: boolean | undefined) {
    this._isManager = value
  }

  public get isSuperManager(): boolean | undefined {
    return this._isSuperManager
  }

  public set isSuperManager(value: boolean | undefined) {
    this._isSuperManager = value
  }

  public get isHasAccessToBonusListAndReport(): boolean | undefined {
    return this._isAdmin || this._isManager || this._isSuperManager
  }
}
