import Base from 'src/entities/Base'

export default interface EmployeeOffer extends Base {
  id: number
  departmentId: number
  employeeId: number
  offerId: number
  totalHours: number
  totalMinutes: number
  totalAmount: number
  days: number
  takeOffDays: number
  isManager: boolean
  note?: string
  isPremium?: boolean
  dateGivenFor: Date
  bonusStatus: BonusStatus
  originalDollarAmount?: number
}

export enum BonusStatus {
  Active,
  Overlapping,
  NotActive
}

export interface EditableEmployeeOffer extends EmployeeOffer {
  dateValidation: string
  amountValidation: string
  noteValidation: string
  isPremiumValidation?: string
}

export function validateEmployeeOfferOnSave(
  employeeOffer: EditableEmployeeOffer,
  month: Date | null,
  isRefund: boolean
) {
  return {
    ...employeeOffer,
    dateValidation:
      !employeeOffer.dateGivenFor || isNaN(employeeOffer.dateGivenFor.getTime())
        ? 'Required'
        : month &&
          (employeeOffer.dateGivenFor.getFullYear() !== month.getFullYear() ||
            employeeOffer.dateGivenFor.getMonth() !== month.getMonth())
        ? 'Insert valid date'
        : undefined,
    amountValidation:
      !employeeOffer.totalAmount || employeeOffer.totalAmount < 0
        ? 'Required'
        : undefined,
    noteValidation:
      !employeeOffer.note || employeeOffer.note === '' ? 'Required' : undefined,
    isPremiumValidation:
      !isRefund && employeeOffer.isPremium === undefined
        ? 'Required'
        : undefined
  } as EditableEmployeeOffer
}
