import {createContext, useContext} from 'react'
import {assert} from 'ts-essentials'
import RootStore from 'src/stores/RootStore'

export const rootStoreContext = createContext<RootStore | null>(null)

export const useRootStore = () => {
  const context = useContext(rootStoreContext)
  assert(context)
  return context
}
