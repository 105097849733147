import {ReactNode} from 'react'
import {Stack, Typography} from '@mui/material'

interface CuiTitleContentProps {
  title: string
  content: ReactNode
  width?: number
}

export default function CuiTitleContent({
  title,
  content,
  width
}: CuiTitleContentProps) {
  return (
    <Stack spacing={1} width={width}>
      <Typography variant="overline" color={theme => theme.palette.grey[500]}>
        {title}
      </Typography>
      {content}
    </Stack>
  )
}
