import {
  Stack,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  TextField,
  InputAdornment
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import {format} from 'date-fns'
import {useState} from 'react'
import CuiAlertDialog from 'src/components/custom/CuiAlertDialog'
import CuiSelectWithOptions from 'src/components/custom/CuiSelectWithOptions'
import Employee from 'src/entities/Employee'
import {useAuth} from 'src/contexts/Auth'
import config from 'src/config'
import CuiCheckboxList from 'src/components/custom/CuiCheckboxList'
import {getEnumOptionWithLabel} from 'src/utils/enumHelper'

enum FilterType {
  Employee = 1,
  Manager,
  Everyone,
  'Everyone (included in bonus offers)' = Everyone
}

export interface SendReportModalProps {
  month: Date | null
  managers: Employee[]
  employees: Employee[]
  closeModal: () => void
}

export default function SendReportModal({
  month,
  managers,
  employees,
  closeModal
}: SendReportModalProps) {
  const [selected, setSelected] = useState<number[]>([])
  const [filterType, setFilterType] = useState<FilterType>()
  const [searchText, setSearchText] = useState('')

  const {fetchWithUser} = useAuth()

  const filterEmployee = (employees: Employee[]) => {
    return employees.filter(
      e =>
        e.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
        e.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
        e.id.toString().includes(searchText.toLowerCase())
    )
  }

  const onSendClick = () => {
    if (month) {
      if (filterType === FilterType.Everyone)
        fetchWithUser(
          config.apiUrl +
            `/reports/sendEmailToAll/${format(month, 'MM,dd,yyyy')}`
        ).then(() => {
          closeModal()
        })
      else if (selected.length > 0) {
        fetchWithUser(
          config.apiUrl +
            `/reports/${
              filterType === FilterType.Manager
                ? 'sendEmailByManagers'
                : 'sendEmailByEmployees'
            }/${format(month, 'MM,dd,yyyy')}`,
          {
            method: 'POST',
            body: JSON.stringify(selected),
            headers: {
              'Content-Type': 'application/json'
            }
          }
        ).then(() => {
          closeModal()
        })
      } else {
        closeModal()
      }
    }
  }

  return (
    <>
      <CuiAlertDialog
        fullWidth
        open
        close={closeModal}
        title="Send report via email"
        okButtonText="Send"
        okClick={onSendClick}
        body={
          <Stack mt={3} spacing={3} height="55vh">
            <CuiSelectWithOptions
              fullWidth
              label="To"
              options={getEnumOptionWithLabel(FilterType)}
              onChange={e => {
                setFilterType(Number(e.target.value))
                setSelected([])
                setSearchText('')
              }}
              defaultValue={''}
            />
            {(filterType === FilterType.Employee ||
              filterType === FilterType.Manager) && (
              <>
                <TextField
                  fullWidth
                  placeholder="Search..."
                  onChange={event => setSearchText(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
                <CuiCheckboxList
                  options={filterEmployee(
                    filterType === FilterType.Employee ? employees : managers
                  )}
                  format={(employee: Employee) => (
                    <>
                      <ListItemAvatar>
                        <Avatar
                          sx={{bgcolor: `#${employee.color}`, color: 'white'}}
                        >
                          <Typography>
                            {employee.lastName[0]}
                            {employee.firstName[0]}
                          </Typography>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${employee.lastName} ${employee.firstName} -(${employee.id})`}
                      />
                    </>
                  )}
                  selectedOptions={selected}
                  setSelectedOptions={setSelected}
                />
              </>
            )}
          </Stack>
        }
      />
    </>
  )
}
