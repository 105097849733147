import {Stack, Typography, Card, CardContent, Button} from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import {useNavigate} from 'react-router-dom'

export default function AccessDenied() {
  const navigate = useNavigate()

  const onLogoutClick = async () => {
    await navigate('/logout')
    navigate('/login')
  }

  return (
    <>
      <Stack
        mt={2}
        mr={2}
        justifyContent="flex-end"
        direction="row"
        color="text.primary"
      >
        <Button
          variant="text"
          color="inherit"
          onClick={onLogoutClick}
          startIcon={<LogoutIcon />}
        >
          Logout
        </Button>
      </Stack>
      <Stack
        alignItems="center"
        justifyContent="center"
        height="100%"
        spacing={3}
      >
        <Card sx={{width: 680, height: 480}}>
          <CardContent>
            <Stack alignItems="center" spacing={5}>
              <img
                width={250}
                src="/assets/icons/ic_access_denied.svg"
                alt="accessDenied"
              />
              <Typography component="h1" variant="h4">
                Access denied
              </Typography>
              <Typography sx={{textAlign: 'center'}} color="GrayText">
                Access denied. Contact: Bonus-Support@Citybook.co.il.
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  )
}
