import React from 'react'
import {Typography} from '@mui/material'
interface CuiSectionHeaderProps {
  text: string
}
export default function CuiSectionHeader({text}: CuiSectionHeaderProps) {
  return (
    <Typography
      sx={{fontWeight: 'bold', fontSize: 12, color: 'text.secondary'}}
    >
      {text}
    </Typography>
  )
}
