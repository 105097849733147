import EmployeeOffer from 'src/entities/EmployeeOffer'
import EmployeeOffersHours from 'src/entities/EmployeeOffersHours'
import Offer from 'src/entities/Offer'

export const createTotalRow = (offers: Offer[], totalEmployees: number) => {
  const allOffers = offers.map(col => ({
    offerId: col.id,
    totalHours: 0,
    totalMinutes: 0,
    totalAmount: 0,
    totalAmountDollars: 0,
    takeOffDays: 0
  }))
  const totalRow = {
    id: 0,
    totalEmployees: totalEmployees,
    totalBasicMinutes: 0,
    totalActualMinutes: 0,
    plusMinutes: 0,
    minusMinutes: 0,
    totalOverAmount: 0,
    totalOverHours: 0,
    totalOverMinutes: 0,
    bonuses: allOffers
  }
  return totalRow
}

export const setTotalWorkHours = (
  totalRow: any,
  employee: EmployeeOffersHours
) => {
  totalRow.totalActualMinutes += employee.totalActualMinutes
  totalRow.totalBasicMinutes += employee.totalBasicMinutes

  if (employee.totalActualMinutes < employee.totalBasicMinutes) {
    totalRow.minusMinutes +=
      employee.totalBasicMinutes - employee.totalActualMinutes
  } else {
    totalRow.plusMinutes +=
      employee.totalActualMinutes - employee.totalBasicMinutes
  }
}

export const setTotalAmountHoursMinutes = (
  totalRow: any,
  bonus: EmployeeOffer
) => {
  var index = totalRow.bonuses?.findIndex(
    (b: EmployeeOffer) => b.offerId === bonus.offerId
  )
  if (index > -1) {
    totalRow.bonuses[index].totalHours += bonus.totalHours
    totalRow.bonuses[index].totalMinutes += bonus.totalMinutes
    if (totalRow.bonuses[index].totalMinutes > 59) {
      totalRow.bonuses[index].totalHours++
      totalRow.bonuses[index].totalMinutes -= 60
    }
    if (bonus.originalDollarAmount != null) {
      totalRow.bonuses[index].totalAmountDollars += bonus.originalDollarAmount
    } else {
      totalRow.bonuses[index].totalAmount += bonus.totalAmount
    }
    totalRow.bonuses[index].takeOffDays += bonus.takeOffDays
  }
}
export const setTotalColumn = (
  totalRow: any,
  totalOverMinutes: number,
  totalOverHours: number,
  totalOverAmount: number
) => {
  totalRow.totalOverMinutes += totalOverMinutes
  totalRow.totalOverHours += totalOverHours
  if (totalRow.totalOverMinutes > 59) {
    totalRow.totalOverHours++
    totalRow.totalOverMinutes -= 60
  }
  totalRow.totalOverAmount += totalOverAmount
}
