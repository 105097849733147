import {Box, Stack, TextField, Typography} from '@mui/material'
import {format} from 'date-fns'
import BonusMonth, {ChangeCurrencyData} from 'src/entities/BonusMonth'
import CuiAlertDialog from 'src/components/custom/CuiAlertDialog'

export interface DollarRateModalProps {
  currencyData?: ChangeCurrencyData
  currentLockMonth: BonusMonth
  setCurrentLockMonth: React.Dispatch<React.SetStateAction<BonusMonth>>
  onClose: () => void
  onOkClick: () => void
}

export default function DollarRateModal({
  currencyData,
  currentLockMonth,
  setCurrentLockMonth,
  onClose,
  onOkClick
}: DollarRateModalProps) {
  return (
    <CuiAlertDialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '440px'
          }
        }
      }}
      open
      close={onClose}
      okButtonText="Lock"
      okClick={onOkClick}
      body={
        <Stack alignItems="center" spacing={1}>
          <Box mb={2}>
            <img
              src="/assets/icons/ic_dollar.svg"
              alt="dollar"
              height="200px"
              width="200px"
            />
          </Box>
          <Typography fontSize="24px" fontWeight={700}>
            {currencyData?.dollarRate
              ? 'Dollar rate'
              : 'No exchange rate was found'}
          </Typography>
          <Box pr={2} pl={2}>
            {currencyData?.dollarRate ? (
              <Stack direction="row" spacing={1}>
                <Typography textAlign="center" fontSize="16px">
                  The exchange rate up to date as of
                </Typography>
                <Typography textAlign="center" fontSize="16px" color="primary">
                  {format(new Date(currencyData.rateDate), 'dd.MM.yy')}
                </Typography>
                <Typography textAlign="center" fontSize="16px">
                  is:
                </Typography>
              </Stack>
            ) : (
              <Typography textAlign="center" fontSize="16px">
                Please fill in the rate
              </Typography>
            )}
          </Box>
          <TextField
            size="small"
            fullWidth
            type="number"
            onKeyPress={event => {
              if (event?.key === '-' || event?.key === '+') {
                event.preventDefault()
              }
            }}
            placeholder="Dollar Rate"
            value={currentLockMonth.dollarRate || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCurrentLockMonth(p => ({
                ...p,
                dollarRate: Number(event.target.value)
              }))
            }}
          />
          <TextField
            fullWidth
            size="small"
            type="number"
            placeholder="Comment"
            multiline
            rows={2}
            value={currentLockMonth.comment}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCurrentLockMonth(p => ({
                ...p,
                comment: event.target.value
              }))
            }}
          />
        </Stack>
      }
    />
  )
}
