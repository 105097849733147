import {useCallback, useEffect, useState} from 'react'
import {
  Autocomplete,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Button
} from '@mui/material'
import {
  GridLinkOperator,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport
} from '@mui/x-data-grid-pro'
import CuiSelectWithOptions from 'src/components/custom/CuiSelectWithOptions'
import CuiMonthPicker from 'src/components/custom/CuiMonthPicker'
import Department from 'src/entities/Department'
import {GridColumn} from 'src/components/bonus/BonusTable'
import {changeFilterData} from 'src/components/bonus/CuiExternalFilter'
import BonusMonth, {BonusMonthStatus} from 'src/entities/BonusMonth'
import config from 'src/config'
import {useAuth} from 'src/contexts/Auth'
import {compareYearMonth} from 'src/utils/dateHelper'
import Employee from 'src/entities/Employee'
import {useRootStore} from 'src/stores/UseStore'
import {observer} from 'mobx-react-lite'

interface ToolbarProps {
  checked: boolean
  setChecked: (val: boolean) => void
  department: number
  loading: boolean
  departments: Department[]
  managers: Employee[] | undefined
  employees: string[]
  resetManagersEmployees: () => void
  searchEmployees: Employee[] | undefined
}

const Toolbar = observer(
  ({
    checked,
    setChecked,
    loading,
    departments,
    managers,
    employees,
    resetManagersEmployees,
    searchEmployees
  }: ToolbarProps) => {
    const [bonusMonthList, setBonusMonthList] = useState<BonusMonth[]>()
    const {fetchWithUser} = useAuth()

    const {filterStore} = useRootStore()

    const onChangeFilterModel = useCallback(
      (columnField: string, value: any, operatorValue: string = 'contain') => {
        filterStore.filterModel = {
          ...filterStore.filterModel,
          items: changeFilterData(
            filterStore.filterModel.items,
            columnField,
            value,
            operatorValue
          )
        }
      },
      [filterStore]
    )

    useEffect(() => {
      filterStore.monthStatus =
        bonusMonthList?.find(b =>
          compareYearMonth(b.month, filterStore.month || new Date())
        )?.status || BonusMonthStatus.Open
    }, [
      bonusMonthList,
      filterStore.month,
      filterStore.monthStatus,
      filterStore
    ])

    useEffect(() => {
      fetchWithUser(config.apiUrl + '/bonusMonth')
        .then(res => res.json())
        .then((data: BonusMonth[]) => {
          setBonusMonthList(data)
        })
    }, [fetchWithUser])

    return (
      <>
        <Stack direction="row" p={2} mt={1} spacing={1}>
          <CuiMonthPicker
            date={filterStore.month}
            setDate={(val: Date) => {
              filterStore.month = val
              resetManagersEmployees()
            }}
            bonusMonthList={bonusMonthList}
            setMonthStatus={(val: BonusMonthStatus | undefined) =>
              (filterStore.monthStatus = val)
            }
            loading={loading}
          />
          {managers && (
            <Autocomplete
              disabled={managers.length === 0}
              sx={{minWidth: 200, width: 200}}
              options={managers}
              getOptionLabel={m => `${m.firstName} ${m.lastName}`}
              onChange={(event: any, newValue: Employee | null) => {
                filterStore.filterModel = {
                  items: [],
                  linkOperator: GridLinkOperator.And
                }
                filterStore.search.manager = Number(newValue?.id || 0)
              }}
              value={
                managers.length
                  ? managers.find(m => m.id === filterStore.search.manager) ||
                    null
                  : null
              }
              renderInput={params => (
                <TextField
                  {...params}
                  label="Manager"
                  placeholder="Select manager"
                />
              )}
            />
          )}
          <CuiSelectWithOptions
            disabled={departments.length === 0}
            value={
              departments.length
                ? departments.find(
                    d =>
                      d.id ===
                      filterStore.filterModel.items.find(
                        x => x.columnField === GridColumn.Department
                      )?.value
                  )?.id || ''
                : ''
            }
            label="Team"
            options={departments.map(d => ({value: d.id, label: d.name}))}
            onChange={e =>
              onChangeFilterModel(
                GridColumn.Department,
                e.target.value,
                'contains'
              )
            }
            withAllOption
          />
          {employees.length ||
          !searchEmployees ||
          filterStore.search.manager ? (
            <Autocomplete
              disabled={employees.length === 0}
              value={
                employees.length
                  ? employees.find(
                      e =>
                        e ===
                        filterStore.filterModel.items.find(
                          x => x.columnField === GridColumn.Employee
                        )?.value
                    ) || null
                  : null
              }
              fullWidth
              options={employees}
              onChange={(event, newValue: string | null) => {
                onChangeFilterModel(
                  GridColumn.Employee,
                  newValue,
                  'quickSearch'
                )
              }}
              renderInput={params => (
                <TextField
                  label="Employee"
                  placeholder="Search employee"
                  {...params}
                />
              )}
            />
          ) : (
            <Autocomplete
              disabled={searchEmployees.length === 0}
              fullWidth
              options={searchEmployees}
              getOptionLabel={e => `${e.id} - ${e.lastName} ${e.firstName}`}
              onChange={(event: any, newValue: Employee | null) => {
                filterStore.filterModel = {
                  items: [
                    {
                      columnField: GridColumn.Employee,
                      operatorValue: 'quickSearch',
                      value: `${newValue?.id} - ${newValue?.lastName} ${newValue?.firstName}`,
                      id: 1
                    }
                  ],
                  linkOperator: GridLinkOperator.And
                }
                filterStore.search.employee = Number(newValue?.id || 0)
              }}
              value={
                searchEmployees.length
                  ? searchEmployees.find(
                      e => e.id === filterStore.search.employee
                    ) || null
                  : null
              }
              renderInput={params => (
                <TextField
                  {...params}
                  label="Employee"
                  placeholder="Select Employee"
                />
              )}
            />
          )}
          {searchEmployees && (
            <Button
              variant="text"
              sx={{width: '150px'}}
              onClick={() => {
                filterStore.reset()
              }}
            >
              Clear all
            </Button>
          )}
        </Stack>
        <GridToolbarContainer>
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Stack flexDirection="row">
              <GridToolbarDensitySelector />
              <GridToolbarExport
                printOptions={{disableToolbarButton: true}}
                csvOptions={{
                  fileName: 'BonusListCB',
                  utf8WithBom: true
                }}
              />
            </Stack>
            <FormControlLabel
              checked={checked}
              onChange={(e, c) => {
                setChecked(c)
              }}
              control={<Switch />}
              label="Display Offer Details"
            />
          </Stack>
        </GridToolbarContainer>
      </>
    )
  }
)

export default Toolbar
