import React from 'react'
import {Button} from '@mui/material'
import CuiProgressButton from 'src/components/custom/CuiProgressButton'

interface CuiCancelSaveButtonProps {
  onCancel: () => void
  onSave: () => void
  disableCancel?: boolean
  disableSave?: boolean
  loading?: boolean
  saveButtonText?: string
}

export default function CuiCancelSaveButton({
  onCancel,
  onSave,
  disableCancel,
  disableSave,
  saveButtonText,
  loading
}: CuiCancelSaveButtonProps) {
  return (
    <>
      <Button
        color="inherit"
        variant="outlined"
        onClick={onCancel}
        disabled={disableCancel}
      >
        Cancel
      </Button>
      <CuiProgressButton
        variant="contained"
        onClick={onSave}
        loading={loading}
        disabled={disableSave}
      >
        {saveButtonText ?? 'Save'}
      </CuiProgressButton>
    </>
  )
}
