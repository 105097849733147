import {useCallback, useEffect, useState} from 'react'
import {Button, Fab, Stack, Typography} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import {format} from 'date-fns'
import {observer} from 'mobx-react-lite'
import OfferModal from 'src/components/offer/OfferModal'
import BonusTable from 'src/components/bonus/BonusTable'
import {useAuth} from 'src/contexts/Auth'
import config from 'src/config'
import EmployeeOffersHours from 'src/entities/EmployeeOffersHours'
import Offer, {
  BonusTypeId,
  isCalculatedType,
  sortOffers
} from 'src/entities/Offer'
import Department from 'src/entities/Department'
import EmployeeOffer from 'src/entities/EmployeeOffer'
import BonusListData from 'src/entities/BonusListData'
import EmployeeOfferUpdated from 'src/entities/EmployeeOfferUpdated'
import {BonusMonthStatus} from 'src/entities/BonusMonth'
import SvgIconStyle from 'src/components/SvgIconStyle'
import {useSnackbar} from 'notistack'
import {compareDates, getHeaderDate} from 'src/utils/dateHelper'
import ManagerRow from 'src/entities/ManagerRow'
import ManagerWorkHours from 'src/entities/ManagerWorkHours'
import {Status} from 'src/entities/ManagerOfferWorkHours'
import Employee from 'src/entities/Employee'
import {useRootStore} from 'src/stores/UseStore'
import {getLocalDateFromUtc} from 'src/utils/formatTime'
import RefundGoodJobModal from 'src/components/bonus/RefundGoodJobModal'
export type RowChangeType = 'new' | 'update' | 'delete' | 'updateDates'

export const BonusListPage = observer(() => {
  const [openCreateOfferModal, setOpenCreateOfferModal] = useState(false)
  const [employees, setEmployees] = useState<EmployeeOffersHours[]>([])
  const [offers, setOffers] = useState<Offer[]>([])
  const [loading, setLoading] = useState(false)
  const [currentOfferId, setCurrentOfferId] = useState(0)
  const [editOfferId, setEditOfferId] = useState(0)
  const [selectedEmployees, setSelectedEmployees] = useState<
    EmployeeOffersHours[]
  >([])
  const [openBonusModalType, setOpenBonusModalType] = useState<
    BonusTypeId | undefined
  >()
  const [departments, setDepartments] = useState<Department[]>([])
  const [newRows, setNewRows] = useState<RowChangeType>()
  const [updateTime, setUpdateTime] = useState<Date>()
  const [openOfferModal, setOpenOfferModal] = useState<boolean>(false)
  const [selectedManager, setSelectedManager] = useState<ManagerRow>(
    {} as ManagerRow
  )

  const [managers, setManagers] = useState<Employee[]>()
  const [searchEmployees, setSearchEmployees] = useState<Employee[]>()
  const {fetchWithUser} = useAuth()
  const {roleStore, filterStore} = useRootStore()

  const {enqueueSnackbar} = useSnackbar()

  useEffect(() => {
    if (roleStore.isAdmin || roleStore.isSuperManager) {
      fetchWithUser(config.apiUrl + '/admin/SetMeAsAdmin', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
    }
  }, [roleStore, fetchWithUser])

  const getData = useCallback(
    (refresh: boolean) => {
      setLoading(true)
      const urlFunction =
        filterStore.monthStatus === BonusMonthStatus.Locked
          ? 'GetBonusListDataLockedMonth'
          : refresh
          ? 'RefreshBonusListDataOpenMonth'
          : 'GetBonusListDataOpenMonth'
      fetchWithUser(
        config.apiUrl +
          `/bonusListData/${urlFunction}/${format(
            filterStore.month,
            'MM,dd,yyyy'
          )}`
      )
        .then(res => res.json())
        .then((data: BonusListData) => {
          setOffers(
            data.offers
              ? data.offers.map(t => ({
                  ...t,
                  fromDate: new Date(t.fromDate),
                  toDate: new Date(t.toDate)
                }))
              : []
          )
          setEmployees(
            data.employeeOffersHours.map(e => ({
              ...e,
              color: Math.floor(Math.random() * 16777215).toString(16)
            })) ?? []
          )
          setLoading(false)
          setNewRows('new')
          if (filterStore.monthStatus === BonusMonthStatus.Open)
            setUpdateTime(getLocalDateFromUtc(data.lastRefresh))
          else setUpdateTime(undefined)
        })
    },
    [fetchWithUser, filterStore.monthStatus, filterStore.month]
  )
  const getMyDepartments = useCallback(() => {
    fetchWithUser(
      config.apiUrl +
        `/departments/GetByManagerId/${format(filterStore.month, 'MM,dd,yyyy')}`
    )
      .then(res => res.json())
      .then((data: Department[]) => {
        if (data) {
          setDepartments(data)
        }
      })
  }, [fetchWithUser, filterStore.month])

  useEffect(() => {
    if (!filterStore.month || isNaN(filterStore.month.getTime())) {
      filterStore.month = new Date()
    }
    if (!filterStore.monthStatus) return
    else {
      if (roleStore.isAdmin || roleStore.isSuperManager) {
        setLoading(true)
        setManagers([])
        resetData()
        fetchWithUser(
          config.apiUrl +
            `/admin/${
              roleStore.isAdmin ? 'GetAllManagers' : 'GetMyManagers'
            }/${format(filterStore.month, 'MM,dd,yyyy')}`
        )
          .then(res => res.json())
          .then((data: Employee[]) => {
            if (data) {
              setManagers(data)
              if (roleStore.isSuperManager) {
                setLoading(false)
              }
            }
          })
        if (roleStore.isAdmin) {
          fetchWithUser(
            config.apiUrl +
              `/employees/GetAll/${format(filterStore.month, 'MM,dd,yyyy')}`
          )
            .then(res => res.json())
            .then((data: Employee[]) => {
              if (data) {
                setSearchEmployees(data)
                setLoading(false)
              }
            })
        }
      } else {
        getData(false)
        getMyDepartments()
      }
    }
  }, [
    fetchWithUser,
    filterStore.monthStatus,
    filterStore.month,
    getData,
    roleStore,
    getMyDepartments,
    filterStore
  ])

  useEffect(() => {
    if (
      (filterStore.search.manager === 0 && managers?.length) ||
      (filterStore.search.manager &&
        managers?.find(m => m.id === filterStore.search.manager))
    ) {
      setLoading(true)
      resetData()
      fetchWithUser(
        config.apiUrl + `/admin/SetMeAsManager/${filterStore.search.manager}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(res => res.json())
        .then((data: boolean) => {
          if (data) {
            getData(false)
            getMyDepartments()
          } else {
            setLoading(false)
          }
        })
    }
  }, [
    filterStore.search.manager,
    fetchWithUser,
    getData,
    getMyDepartments,
    managers,
    filterStore
  ])

  useEffect(() => {
    if (
      filterStore.search.employee &&
      searchEmployees?.find(m => m.id === filterStore.search.employee)
    ) {
      setLoading(true)
      resetData()
      fetchWithUser(
        config.apiUrl +
          `/admin/GetMyManager/${filterStore.search.employee}/${format(
            filterStore.month,
            'MM,dd,yyyy'
          )}`
      )
        .then(res => res.json())
        .then((manager: number) => {
          if (manager) {
            filterStore.search = {manager: manager, employee: 0}
          } else {
            setLoading(false)
          }
        })
    }
  }, [
    filterStore.search.employee,
    fetchWithUser,
    getData,
    getMyDepartments,
    searchEmployees,
    filterStore
  ])

  const resetData = () => {
    setUpdateTime(undefined)
    setDepartments([])
    setEmployees([])
    setOffers([])
  }

  const addOfferEmployees = (
    bonusesToAdd: EmployeeOffer[],
    offerToAdd: Offer
  ) => {
    const addOffer =
      offerToAdd.bonusTypeId === BonusTypeId.Overtime ||
      offerToAdd.bonusTypeId === BonusTypeId.NotTakeOff ||
      offerToAdd.bonusTypeId === BonusTypeId.PeriodHours
        ? `offer ${getHeaderDate(offerToAdd)}`
        : BonusTypeId[offerToAdd.bonusTypeId]
    enqueueSnackbar(`${addOffer} was created successfully!`)
    setEmployees(prev =>
      prev.map(e => {
        const bonuses = bonusesToAdd.filter(d => d.employeeId === e.id)
        return {
          ...e,
          bonuses: e.bonuses
            .filter(b => !bonuses.find(bonus => b.offerId === bonus.offerId))
            .concat(bonuses)
        }
      })
    )
    offerToAdd.id = bonusesToAdd[0].offerId
    const offersHelp = [...offers]
    const index = offersHelp.findIndex(o => o.id === offerToAdd.id)
    if (index === -1) {
      offersHelp.push(offerToAdd)
      setOffers(sortOffers(offersHelp))
    }
    setNewRows('update')
    setCurrentOfferId(offerToAdd.id)
  }

  const checkIsChangedOfferDate = (offer: Offer) => {
    return (
      isCalculatedType(offer.bonusTypeId) &&
      offers.find(
        o =>
          o.id === offer.id &&
          (!compareDates(o.fromDate, offer.fromDate) ||
            !compareDates(o.toDate, offer.toDate))
      )
    )
  }

  const updateOfferEmployees = (
    bonuses: EmployeeOfferUpdated,
    offerToAdd: Offer
  ) => {
    enqueueSnackbar(
      `offer ${getHeaderDate(offerToAdd)} was updated successfully`
    )
    setEmployees(prev =>
      prev.map(e => {
        const bs = bonuses.employeeOffersUpdated.filter(
          d => d.employeeId === e.id
        )
        return {
          ...e,
          bonuses: e.bonuses
            .filter(
              b =>
                b.offerId !== offerToAdd.id &&
                !bs.find(bonus => b.offerId === bonus.offerId)
            )
            .concat(bs)
        }
      })
    )
    setOffers(prev =>
      sortOffers(prev.map(o => (o.id === offerToAdd.id ? offerToAdd : o)))
    )
    setCurrentOfferId(offerToAdd.id)
    if (checkIsChangedOfferDate(offerToAdd)) setNewRows('updateDates')
    else setNewRows('update')
  }

  const deleteOffer = (offerId: number) => {
    fetchWithUser(
      config.apiUrl +
        `/offers/${offerId}/${format(filterStore.month, 'MM,dd,yyyy')}`,
      {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      }
    )
      .then(res => res.json())
      .then((bonuses: EmployeeOffer[]) => {
        setOffers(prev => prev.filter(o => o.id !== offerId))
        setEmployees(prev =>
          prev.map(e => {
            const bs = bonuses.filter(d => d.employeeId === e.id)
            return {
              ...e,
              bonuses: e.bonuses
                .filter(
                  b =>
                    b.offerId !== offerId &&
                    !bs.find(bonus => b.offerId === bonus.offerId)
                )
                .concat(bs)
            }
          })
        )
        setNewRows('delete')
      })
  }

  const deleteEmployeeOffer = (id: number) => {
    setEmployees(prev =>
      prev.map(e => {
        return {
          ...e,
          bonuses: e.bonuses.filter(b => b.id !== id)
        }
      })
    )
    setNewRows('update')
  }

  const updateManagerOvertime = (offerHours: ManagerWorkHours) => {
    setEmployees(prev =>
      prev.map(e =>
        e.isManager && e.bonuses
          ? {
              ...e,
              bonuses: e.bonuses.map(b =>
                offerHours.managerOfferWorkHours[b.offerId]?.some(
                  mw => mw.status === Status.DB
                )
                  ? {
                      ...b,
                      totalHours:
                        offerHours.sumOfferOTHours[b.offerId].totalHours,
                      totalMinutes:
                        offerHours.sumOfferOTHours[b.offerId].totalMinutes,
                      totalAmount: 0
                    }
                  : b
              )
            }
          : e
      )
    )
    setNewRows('update')
  }

  const updateManagerNotTakeOff = (managerBonuses: EmployeeOffer[]) => {
    setEmployees(prev =>
      prev.map(e =>
        e.isManager && e.bonuses
          ? {
              ...e,
              bonuses: e.bonuses.map(b => {
                return managerBonuses.find(mb => mb.id === b.id) || b
              })
            }
          : e
      )
    )
    setNewRows('update')
  }

  const onCloseModal = () => {
    setOpenCreateOfferModal(false)
    setEditOfferId(0)
  }
  const openEditModal = (columnId: number) => {
    setOpenCreateOfferModal(true)
    setEditOfferId(columnId)
  }

  return (
    <Stack px={5} spacing={3}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="baseline" spacing={5}>
          <Typography fontWeight="bold" fontSize="x-large">
            Bonus List
          </Typography>
          {updateTime && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography color="text.disabled">
                {' '}
                Last update from Harmony: {format(updateTime, 'dd/MM/yyyy p')}
              </Typography>
              <Button
                color="inherit"
                startIcon={<SvgIconStyle src="/assets/icons/ic_refresh.svg" />}
                onClick={() => {
                  getData(true)
                }}
              >
                Refresh from Harmony
              </Button>
            </Stack>
          )}
        </Stack>
        <Stack direction="row" spacing={2}>
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            onClick={() => {
              setOpenCreateOfferModal(true)
            }}
            disabled={filterStore.monthStatus === BonusMonthStatus.Locked}
          >
            <AddIcon />
            Offer
          </Fab>
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            onClick={() => {
              setOpenBonusModalType(BonusTypeId.Refund)
            }}
            disabled={filterStore.monthStatus === BonusMonthStatus.Locked}
          >
            <AddIcon />
            Refund
          </Fab>
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            onClick={() => {
              setOpenBonusModalType(BonusTypeId.GoodJob)
            }}
            disabled={filterStore.monthStatus === BonusMonthStatus.Locked}
          >
            <AddIcon />
            Good Job
          </Fab>
          {openCreateOfferModal && (
            <OfferModal
              selectedEmployees={selectedEmployees}
              onCloseModal={onCloseModal}
              offerId={editOfferId}
              onCloseEdit={updateOfferEmployees}
              onCloseAdd={addOfferEmployees}
              offers={offers}
              employees={employees}
            />
          )}
          {openBonusModalType === BonusTypeId.Refund && (
            <RefundGoodJobModal
              selectedEmployees={selectedEmployees.map(se => se.id)}
              onCloseModal={() => setOpenBonusModalType(undefined)}
              employees={employees}
              offer={
                offers.find(o => o.bonusTypeId === openBonusModalType) ||
                undefined
              }
              onSave={addOfferEmployees}
              onDelete={deleteEmployeeOffer}
              isRefund={true}
            />
          )}
          {openBonusModalType === BonusTypeId.GoodJob && (
            <RefundGoodJobModal
              selectedEmployees={selectedEmployees.map(se => se.id)}
              onCloseModal={() => setOpenBonusModalType(undefined)}
              employees={employees}
              offer={
                offers.find(o => o.bonusTypeId === openBonusModalType) ||
                undefined
              }
              onSave={addOfferEmployees}
              onDelete={deleteEmployeeOffer}
              isRefund={false}
            />
          )}
        </Stack>
      </Stack>
      <BonusTable
        offers={offers}
        employees={employees}
        loading={loading}
        openEditModal={openEditModal}
        deleteOffer={deleteOffer}
        setSelectedEmployees={setSelectedEmployees}
        departments={departments}
        newRows={newRows}
        setNewRows={setNewRows}
        updateManagerOvertime={updateManagerOvertime}
        updateManagerNotTakeOff={updateManagerNotTakeOff}
        openOfferModal={openOfferModal}
        setOpenOfferModal={setOpenOfferModal}
        selectedManager={selectedManager}
        setSelectedManager={setSelectedManager}
        currentOfferId={currentOfferId}
        managers={managers}
        resetManagersEmployees={() => {
          setManagers([])
          setSearchEmployees([])
        }}
        searchEmployees={searchEmployees}
      />
    </Stack>
  )
})

export default BonusListPage
