import {useCallback, useEffect, useState} from 'react'
import {
  Alert,
  Avatar,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import CuiCancelSaveButton from 'src/components/custom/CuiCancelSaveButton'
import OfferManagerCard from 'src/components/offer/OfferManagerCard'
import config from 'src/config'
import ManagerWorkHours from 'src/entities/ManagerWorkHours'
import {useAuth} from 'src/contexts/Auth'
import {format} from 'date-fns'
import OTHoursBox from 'src/components/offer/OTHoursBox'
import EmployeeOffer, {BonusStatus} from 'src/entities/EmployeeOffer'
import ManagerFreeDay from 'src/entities/ManagerFreeDay'
import Offer, {
  BonusTypeId,
  CheckOverlapping,
  isDaysType,
  isHoursType
} from 'src/entities/Offer'
import {
  daysInMonth,
  displayModalOverHoursAndMinutes,
  displayTimeByHoursAndMinutes
} from 'src/utils/formatTime'
import ManagerRow from 'src/entities/ManagerRow'
import ManagerOfferWorkHour, {Status} from 'src/entities/ManagerOfferWorkHours'
import {BonusMonthStatus} from 'src/entities/BonusMonth'
import EmployeeOfferHour from 'src/entities/EmployeeOfferHour'
import dayjs from 'dayjs'
import HelpIcon from '@mui/icons-material/Help'
import SvgIconStyle from 'src/components/SvgIconStyle'
import {observer} from 'mobx-react-lite'
import {useRootStore} from 'src/stores/UseStore'

interface OfferManagerModalProps {
  onCloseModal: () => void
  offers: Offer[]
  employee: ManagerRow
  updateManagerOvertime: (offerHours: ManagerWorkHours) => void
  updateManagerNotTakeOff: (managerBonuses: EmployeeOffer[]) => void
}

const OffersManagerModal = observer(
  ({
    onCloseModal,
    offers,
    employee,
    updateManagerOvertime,
    updateManagerNotTakeOff
  }: OfferManagerModalProps) => {
    const [offerHours, setOfferHours] = useState<ManagerWorkHours>(
      {} as ManagerWorkHours
    )
    const [offerFreeDays, setOfferFreedays] = useState<ManagerFreeDay>(
      {} as ManagerFreeDay
    )
    const [errorOnSave, setErrorOnSave] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)
    const {fetchWithUser} = useAuth()
    const {filterStore} = useRootStore()
    const bonusType =
      offers.find(o => o.id === employee.offerId)?.bonusTypeId ||
      BonusTypeId.Overtime

    const getOffersList = () => {
      return offers
        .filter(o => o.bonusTypeId === bonusType)
        .filter(o => employee.row.bonuses.find(b => b.offerId === o.id))
    }

    const [bonusesByType, setBonusesByType] = useState(
      employee.row.bonuses.filter(
        b => getOffersList().find(o => o.id === b.offerId) != null
      )
    )
    const [errorOverlappingNotTakeOff, setErrorOverlappingNotTakeOff] =
      useState(false)

    const onCancelClick = () => {
      onCloseModal()
    }

    const onChangeHours = (hours: number, minutes: number, offerId: number) => {
      const sumOfferOTHoursList = offerHours.sumOfferOTHours
      sumOfferOTHoursList[offerId].totalWorkMinutes = minutes + hours * 60
      sumOfferOTHoursList[offerId].totalHours = hours + Math.floor(minutes / 60)
      sumOfferOTHoursList[offerId].totalMinutes = minutes % 60
      setOfferHours(prev => ({...prev, sumOfferOTHours: sumOfferOTHoursList}))
    }

    const onChangeDays = (isAdd: boolean, offerId: number) => {
      setErrorOverlappingNotTakeOff(false)
      setBonusesByType(prev =>
        prev.map(b =>
          b.offerId === offerId
            ? {
                ...b,
                bonusStatus: isAdd ? BonusStatus.Active : BonusStatus.NotActive
              }
            : b
        )
      )
    }

    const onSaveClick = () => {
      if (isHoursType(bonusType)) {
        let sum = 0
        Object.keys(offerHours.sumOfferOTHours).map(
          d => (sum += offerHours.sumOfferOTHours[Number(d)].totalWorkMinutes)
        )
        const error = Object.keys(offerHours.managerOfferWorkHours).map(key =>
          offerHours.managerOfferWorkHours[Number(key)].filter(
            o => o.error === true || o.formtError === true
          )
        )
        const isExist = Object.keys(error).filter(
          d => error[Number(d)].length > 0
        )
        if (sum > offerHours.sumOTHoursSynel.totalWorkMinutes) {
          setErrorOnSave(true)
        } else {
          setErrorOnSave(false)
          if (isExist.length === 0) {
            const EmployeeOfferHours: EmployeeOfferHour[] = []
            Object.keys(offerHours.managerOfferWorkHours).map(key =>
              offerHours.managerOfferWorkHours[Number(key)].filter(
                o =>
                  o.status === Status.DB &&
                  EmployeeOfferHours.push({
                    employeeId: employee.row.employeeId,
                    offerId: o.offerId,
                    totalOTMinutes: o.otMinuts,
                    date: o.date
                  } as EmployeeOfferHour)
              )
            )
            setLoading(true)
            fetchWithUser(
              config.apiUrl +
                `/Manager/AddOrUpdateHours?managerId=${employee.row.employeeId}`,
              {
                method: 'POST',
                body: JSON.stringify(EmployeeOfferHours),
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            ).then(() => {
              updateManagerOvertime(offerHours)
              setLoading(false)
              onCloseModal()
            })
          }
        }
      } else {
        if (CheckOverlapping(bonusesByType, getOffersList())) {
          setErrorOverlappingNotTakeOff(true)
        } else {
          let updateBonuses = bonusesByType.map(b =>
            b.bonusStatus === BonusStatus.Overlapping
              ? {...b, bonusStatus: BonusStatus.NotActive}
              : b
          )
          setLoading(true)
          fetchWithUser(config.apiUrl + '/manager/UpdateNotTakeOffBonuses', {
            method: 'PUT',
            body: JSON.stringify(updateBonuses),
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(() => {
            updateManagerNotTakeOff(updateBonuses)
            setLoading(false)
            onCloseModal()
          })
        }
      }
    }

    const getData = useCallback(
      (url: string, setOffers: (value: any) => void) => {
        return fetchWithUser(
          config.apiUrl +
            `/Manager/${url}?managerId=${
              employee.row.employeeId
            }&fromDate=${format(
              filterStore.month,
              'MM,dd,yyyy'
            )}&bonusTypeId=${bonusType}`
        )
          .then(res => res.json())
          .then(data => {
            if (isHoursType(bonusType)) {
              Object.keys(data.managerOfferWorkHours).map(key =>
                data.managerOfferWorkHours[Number(key)].map(
                  (o: ManagerOfferWorkHour) =>
                    (o.otTime = dayjs(
                      new Date(
                        filterStore.month.getFullYear(),
                        filterStore.month.getMonth(),
                        filterStore.month.getDate(),
                        Math.floor(o.otMinuts / 60),
                        o.otMinuts % 60
                      )
                    ))
                )
              )
            }
            setOffers(data)
          })
      },
      [fetchWithUser, employee.row.employeeId, bonusType, filterStore]
    )

    useEffect(() => {
      bonusType === BonusTypeId.NotTakeOff
        ? getData('ManagerNotTakeOffers', setOfferFreedays)
        : getData('ManagerOvertimeOffers', setOfferHours)
    }, [bonusType, getData])

    return (
      <Dialog fullWidth maxWidth="lg" open>
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              {`Update ${bonusType && BonusTypeId[bonusType]} ${
                isDaysType(bonusType) ? 'days' : 'hours'
              }`}
              <Tooltip
                title="You can update your hours based on the hours displayed from Synel.
In case of overlapping days between offers, make sure the total hours
 are equal to or less than Synel hours.
"
              >
                <HelpIcon sx={{fontSize: '1.0rem', ml: 0.5}} />
              </Tooltip>
            </Stack>
            <IconButton onClick={onCancelClick}>
              <SvgIconStyle src="/assets/icons/ic_close.svg" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack pt={4} spacing={3}>
            <Card sx={{backgroundColor: 'grey.200'}}>
              <CardContent>
                <Stack direction="row" spacing={76} alignItems="center">
                  <Stack direction="row" spacing={2}>
                    <Avatar>
                      <Typography>
                        {employee.row.lastName[0]}
                        {employee.row.firstName[0]}
                      </Typography>
                    </Avatar>
                    <Stack>
                      <Typography variant="subtitle1">
                        {employee.row.firstName + ' ' + employee.row.lastName}
                      </Typography>
                      <Typography color={theme => theme.palette.grey[500]}>
                        Manager
                      </Typography>
                    </Stack>
                  </Stack>
                  <Typography>
                    {isHoursType(bonusType) ? (
                      offerHours?.sumOTHoursSynel?.totalMinutes != null ? (
                        <Typography>
                          {'Overtime in offers range: ' +
                            displayTimeByHoursAndMinutes(
                              offerHours.sumOTHoursSynel.totalHours
                                ? offerHours.sumOTHoursSynel.totalHours
                                : 0,
                              offerHours.sumOTHoursSynel.totalMinutes
                                ? offerHours.sumOTHoursSynel.totalMinutes
                                : 0
                            ) +
                            ' h'}
                        </Typography>
                      ) : (
                        <Stack direction="row">
                          Overtime in offers range:{' '}
                          <Skeleton
                            variant="rectangular"
                            style={{
                              width: 60,
                              height: 40,
                              backgroundColor: 'grey.900'
                            }}
                          />
                        </Stack>
                      )
                    ) : offerFreeDays?.sumFreeDays != null ? (
                      <Typography>
                        {`Days off in the offer range:   ${
                          offerFreeDays?.sumFreeDays
                            ? offerFreeDays?.sumFreeDays
                            : 0
                        } d`}{' '}
                      </Typography>
                    ) : (
                      <Stack direction="row">
                        Days off in the offer range:
                        <Skeleton
                          variant="rectangular"
                          style={{
                            width: 60,
                            height: 40,
                            backgroundColor: 'grey.900'
                          }}
                        />
                      </Stack>
                    )}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
            {bonusType === BonusTypeId.NotTakeOff && (
              <Typography color="#637381">
                In case of overlapping offers, choose one of them
              </Typography>
            )}
            <Stack
              direction="row"
              sx={{
                justifyContent: 'center',
                borderRadius: '8px',
                boxShadow:
                  '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);'
              }}
            >
              {[...Array(daysInMonth(filterStore.month)).keys()].map(d =>
                isHoursType(bonusType) ? (
                  <OTHoursBox
                    key={d}
                    isBorder={
                      offerHours.offersOTHours &&
                      Object.keys(offerHours.offersOTHours).find(
                        k => k === (d + 1).toString()
                      ) &&
                      offerHours.offersOTHours[d + 1].length > 1
                        ? true
                        : false
                    }
                    isHighlight={
                      offerHours.offersOTHours &&
                      Object.keys(offerHours.offersOTHours).find(
                        k => k === (d + 1).toString()
                      ) != null
                    }
                    hours={
                      offerHours?.allOTHours &&
                      displayModalOverHoursAndMinutes(
                        offerHours?.allOTHours[d + 1].totalHours,
                        offerHours?.allOTHours[d + 1].totalMinutes
                      )
                    }
                    day={d + 1}
                    bonusType={bonusType}
                  />
                ) : (
                  bonusType && (
                    <OTHoursBox
                      key={d}
                      isBorder={false}
                      isHighlight={
                        offerFreeDays.offersFreeDays &&
                        Object.keys(offerFreeDays.offersFreeDays).find(
                          k => k === (d + 1).toString()
                        )
                          ? true
                          : false
                      }
                      hours={
                        offerFreeDays?.allFreeDays &&
                        offerFreeDays?.allFreeDays[d + 1]
                          ? 'true'
                          : ''
                      }
                      day={d + 1}
                      bonusType={bonusType}
                    />
                  )
                )
              )}
            </Stack>
            <Stack sx={{marginTop: 3}}>
              {errorOnSave && (
                <Alert severity="error">{`The sum of all overtime for offers cannot exceed your total overtime ( 
                ${
                  displayTimeByHoursAndMinutes(
                    offerHours?.sumOTHoursSynel.totalHours,
                    offerHours?.sumOTHoursSynel.totalMinutes
                  ) + ' h'
                }})`}</Alert>
              )}
              {errorOverlappingNotTakeOff && (
                <Alert severity="error">
                  Overlapping offers are not allowed
                </Alert>
              )}
            </Stack>
            {offerHours &&
              getOffersList().map(offer => (
                <OfferManagerCard
                  key={offer.id}
                  onChangeDays={onChangeDays}
                  onChangeHours={onChangeHours}
                  offer={offer}
                  employee={employee}
                  errorOnSave={errorOnSave}
                  offerHours={offerHours}
                  setOfferHours={setOfferHours}
                />
              ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <CuiCancelSaveButton
            onCancel={onCancelClick}
            onSave={onSaveClick}
            disableSave={filterStore.monthStatus === BonusMonthStatus.Locked}
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    )
  }
)

export default OffersManagerModal
