import {useState} from 'react'
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import AddIcon from '@mui/icons-material/Add'
import {formatDate} from 'src/utils/formatTime'
import CuiCancelSaveButton from 'src/components/custom/CuiCancelSaveButton'
import {useAuth} from 'src/contexts/Auth'
import config from 'src/config'
import Offer, {BonusTypeId} from 'src/entities/Offer'
import EmployeeOffersHours from 'src/entities/EmployeeOffersHours'
import EmployeeOffer, {
  EditableEmployeeOffer,
  validateEmployeeOfferOnSave
} from 'src/entities/EmployeeOffer'
import SvgIconStyle from 'src/components/SvgIconStyle'
import {getUnique} from 'src/utils/arrayHelper'
import {useRootStore} from 'src/stores/UseStore'
import AddOrEditBonus from 'src/components/bonus/AddOrEditBonus'
import DisplayBonus from 'src/components/bonus/DisplayBonus'

interface validateOfferMessages {
  isEmployeesValid: Boolean
  isTotalAmountValid: Boolean
  isHasNoRefunds: Boolean
}
interface RefundGoodJobModalProps {
  onCloseModal: () => void
  offer?: Offer
  selectedEmployees: number[]
  employees: EmployeeOffersHours[]
  onSave: (bonusesToAdd: EmployeeOffer[], offersToAdd: Offer) => void
  onDelete: (val: number) => void
  isRefund: boolean
}

export default function RefundGoodJobModal({
  onCloseModal,
  selectedEmployees,
  employees,
  offer,
  onSave,
  onDelete,
  isRefund
}: RefundGoodJobModalProps) {
  const [loading, setLoading] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState<
    EmployeeOffersHours[]
  >(employees.filter(e => selectedEmployees.includes(e.employeeId)))
  const [validateMessages, setValidateMessages] =
    useState<validateOfferMessages>({
      isEmployeesValid: false,
      isTotalAmountValid: false,
      isHasNoRefunds: false
    })
  const [lastId, setLastId] = useState(0)
  const [addEmployeeOfferList, setAddEmployeeOfferList] = useState<
    EditableEmployeeOffer[]
  >([])
  const [myEmployeeOfferList, setMyEmployeeOfferList] = useState<
    EditableEmployeeOffer[]
  >(
    selectedEmployee.length === 1
      ? selectedEmployee[0].bonuses
          .filter(b => b.offerId === (offer?.id || 0))
          .map(
            b =>
              ({
                ...b,
                dateGivenFor: new Date(b.dateGivenFor)
              } as EditableEmployeeOffer)
          )
      : []
  )

  const {fetchWithUser} = useAuth()

  const {filterStore} = useRootStore()
  const onCancelClick = () => {
    onCloseModal()
  }

  const editValidationOnSave = () => {
    setValidateMessages({
      isEmployeesValid: !selectedEmployee.length,
      isTotalAmountValid: true,
      isHasNoRefunds:
        !addEmployeeOfferList.length && !myEmployeeOfferList.length
    })
    setAddEmployeeOfferList(prev =>
      prev.map(p => validateEmployeeOfferOnSave(p, filterStore.month, isRefund))
    )
    setMyEmployeeOfferList(prev =>
      prev.map(p => validateEmployeeOfferOnSave(p, filterStore.month, isRefund))
    )
  }

  function onSaveClick() {
    if (
      !selectedEmployee.length ||
      (!addEmployeeOfferList.length && !myEmployeeOfferList.length) ||
      [...addEmployeeOfferList, ...myEmployeeOfferList].find(
        eo =>
          !eo.dateGivenFor ||
          isNaN(eo.dateGivenFor.getTime()) ||
          (filterStore.month &&
            (eo.dateGivenFor.getFullYear() !==
              filterStore.month.getFullYear() ||
              eo.dateGivenFor.getMonth() !== filterStore.month.getMonth())) ||
          !eo.totalAmount ||
          eo.totalAmount < 0 ||
          (!isRefund && !eo.note) ||
          (!isRefund && eo.isPremium === undefined)
      )
    ) {
      editValidationOnSave()
    } else {
      setLoading(true)
      let employeeOffers: any[] = []
      myEmployeeOfferList.concat(addEmployeeOfferList).forEach(eo =>
        selectedEmployee.forEach(se => {
          employeeOffers.push({
            ...eo,
            dateGivenFor: formatDate(eo.dateGivenFor),
            id: 0,
            employeeId: se.employeeId,
            isManager: se.isManager
          })
        })
      )
      var d = filterStore.month || new Date()
      const newOffer = {
        ...offer,
        id: offer?.id || 0,
        bonusTypeId: isRefund ? BonusTypeId.Refund : BonusTypeId.GoodJob,
        toDate: new Date(d.getFullYear(), d.getMonth()),
        fromDate: new Date(d.getFullYear(), d.getMonth()),
        employeeOffers: employeeOffers,
        departments: selectedEmployee
          .map(se => ({...se.currentDepartment, employees: []}))
          .filter(getUnique)
      }

      fetchWithUser(
        config.apiUrl +
          `/offers/AddOrUpdateRefundOrGoodJob/${selectedEmployee.length === 1}`,
        {
          method: 'POST',
          body: JSON.stringify({
            ...newOffer,
            fromDate: formatDate(newOffer.fromDate),
            toDate: formatDate(newOffer.toDate)
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(res => res.json())
        .then((data: EmployeeOffer[]) => {
          onSave(data, newOffer)
          setLoading(false)
          onCloseModal()
        })
        .catch(e => {
          console.log(e)
        })
    }
  }

  const onDeleteClick = (id: number) => {
    fetchWithUser(config.apiUrl + `/offers/DeleteEmployeeOffer/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(() => {
      setMyEmployeeOfferList(prev => prev.filter(eo => eo.id !== id))
      onDelete(id)
    })
  }

  const onChangeEmployees = (employees: EmployeeOffersHours[]) => {
    setSelectedEmployee(employees)
    setValidateMessages(prev => ({
      ...prev,
      isEmployeesValid: false,
      isHasNoRefunds: false
    }))
  }

  return (
    <Dialog fullWidth maxWidth="sm" open>
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {isRefund
            ? myEmployeeOfferList.length
              ? 'Refund Details'
              : 'Add Refund'
            : myEmployeeOfferList.length
            ? 'Good Job Details'
            : 'Add Good Job'}
          <IconButton onClick={onCancelClick}>
            <SvgIconStyle src="/assets/icons/ic_close.svg" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Autocomplete
            sx={{paddingTop: 2}}
            disableCloseOnSelect
            multiple
            limitTags={3}
            options={employees}
            value={selectedEmployee}
            getOptionLabel={e => e.firstName + ' ' + e.lastName}
            onChange={(event: any, newValue: EmployeeOffersHours[]) => {
              if (newValue.length === 1)
                setMyEmployeeOfferList(
                  newValue[0].bonuses
                    .filter(b => b.offerId === (offer?.id || 0))
                    .map(
                      b =>
                        ({
                          ...b,
                          dateGivenFor: new Date(b.dateGivenFor)
                        } as EditableEmployeeOffer)
                    )
                )
              else {
                setMyEmployeeOfferList([])
              }
              onChangeEmployees(newValue)
            }}
            renderInput={params => (
              <TextField
                error={!!validateMessages.isEmployeesValid}
                helperText={
                  validateMessages.isEmployeesValid ? 'Required' : undefined
                }
                {...params}
                label="Employee"
              />
            )}
          />
          {myEmployeeOfferList.map(eo => (
            <DisplayBonus
              key={eo.id}
              employeeOffer={eo}
              setEmployeeOfferList={setMyEmployeeOfferList}
              onDelete={onDeleteClick}
              isRefund={isRefund}
            />
          ))}
        </Stack>
        {addEmployeeOfferList.map(eo => (
          <AddOrEditBonus
            key={eo.id}
            onDelete={(id: number) => {
              setAddEmployeeOfferList(prev => prev.filter(eo => eo.id !== id))
            }}
            employeeOffer={eo}
            setEmployeeOfferList={setAddEmployeeOfferList}
            isRefund={isRefund}
          />
        ))}
        {validateMessages.isHasNoRefunds && (
          <Stack
            marginTop={2}
            direction="row"
            alignItems="center"
            spacing={0.5}
          >
            <Typography>
              <ErrorIcon fontSize="medium" color="error" />
            </Typography>
            <Typography color={'#7A0C2E'}>
              {isRefund ? `No refund was added` : `No good job was added`}
            </Typography>
          </Stack>
        )}
        <Button
          sx={{mt: 3}}
          variant="text"
          color="primary"
          onClick={() => {
            setAddEmployeeOfferList(prev => [
              ...prev,
              {
                offerId: offer?.id || 0,
                id: lastId,
                totalAmount: 0,
                note: '',
                dateGivenFor: new Date()
              } as EditableEmployeeOffer
            ])
            setLastId(prev => prev + 1)
            setValidateMessages(prev => ({...prev, isHasNoRefunds: false}))
          }}
          startIcon={<AddIcon color="primary" fontSize="small" />}
        >
          {isRefund ? 'Add Refund' : 'Add Good Job'}
        </Button>
        <Typography sx={{marginTop: 3, marginLeft: 3}} variant="h6">
          Total Amount:{' '}
          {addEmployeeOfferList.reduce((accumulator, eo) => {
            return accumulator + eo.totalAmount
          }, 0) +
            myEmployeeOfferList.reduce((accumulator, eo) => {
              return accumulator + eo.totalAmount
            }, 0)}
        </Typography>
      </DialogContent>
      <DialogActions>
        <CuiCancelSaveButton
          onCancel={onCancelClick}
          onSave={onSaveClick}
          loading={loading}
          disableSave={loading}
        />
      </DialogActions>
    </Dialog>
  )
}
