import React from 'react'
import {Stack, SxProps, Typography} from '@mui/material'
import {ErrorIcon} from 'src/theme/overrides/CustomIcons'

export interface CuiAlertValidationProps {
  errorMessage: string
  sx?: SxProps
}

export default function CuiAlertValidation({
  errorMessage,
  sx = []
}: CuiAlertValidationProps) {
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
    >
      <ErrorIcon color="error" />
      <Typography color={'error.darker'}>{errorMessage}</Typography>
    </Stack>
  )
}
