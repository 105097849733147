import Box from '@mui/material/Box'
import List from '@mui/material/List'
import CuiListItemIconWithText from 'src/components/custom/dataGrid/CuiListItemIconWithText'

export default function ColumnMenu({
  openEditModal,
  openDeleteModal,
  isLockedMonth
}: {
  openEditModal: () => void
  openDeleteModal: () => void
  isLockedMonth: boolean
}) {
  return (
    <Box boxShadow={2} borderRadius={1}>
      <List>
        <CuiListItemIconWithText
          src="/assets/icons/ic_expand.svg"
          text="Open Details"
          onClick={openEditModal}
        />
        <CuiListItemIconWithText
          disabled={isLockedMonth}
          src="/assets/icons/ic_trash.svg"
          text="Delete"
          onClick={openDeleteModal}
        />
      </List>
    </Box>
  )
}
