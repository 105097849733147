// @mui
import {styled} from '@mui/material/styles'
import {List, Box, ListSubheader} from '@mui/material'
// type
import {NavSectionProps} from '../type'
//
import {NavListRoot} from './NavList'
import {useRootStore} from 'src/stores/UseStore'
import {observer} from 'mobx-react-lite'

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled(props => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({theme}) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter
  })
}))

// ----------------------------------------------------------------------

export const NavSectionVertical = observer(
  ({navConfig, isCollapse = false, ...other}: NavSectionProps) => {
    const {roleStore} = useRootStore()

    return (
      <Box {...other}>
        {navConfig.map(group => (
          <List key={group.subheader} disablePadding sx={{px: 2}}>
            <ListSubheaderStyle
              sx={{
                ...(isCollapse && {
                  opacity: 0
                })
              }}
            >
              {group.subheader}
            </ListSubheaderStyle>
            {group.items.map(list =>
              (roleStore.isOrgAdmin && list.title === 'Organization Tree') ||
              (roleStore.isHasAccessToBonusListAndReport &&
                (list.title === 'Bonus List' || list.title === 'Reports')) ? (
                <NavListRoot
                  key={list.title + list.path}
                  list={list}
                  isCollapse={isCollapse}
                />
              ) : (
                ''
              )
            )}
          </List>
        ))}
      </Box>
    )
  }
)

export default NavSectionVertical
