import React, {useState} from 'react'
import {
  Stack,
  Card,
  CardContent,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid
} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import {LocalizationProvider, TimePicker} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import ManagerWorkHours from 'src/entities/ManagerWorkHours'
import {displayModalOverHoursAndMinutes} from 'src/utils/formatTime'
import ManagerRow from 'src/entities/ManagerRow'
import CuiTitleContent from 'src/components/custom/CuiTitleContent'
import {Status} from 'src/entities/ManagerOfferWorkHours'
import {BonusMonthStatus} from 'src/entities/BonusMonth'
import {InfoIcon} from 'src/theme/overrides/CustomIcons'
import {compareYearMonth, getHeaderDate} from 'src/utils/dateHelper'
import {BonusStatus} from 'src/entities/EmployeeOffer'
import Offer, {BonusTypeId, isHoursType} from 'src/entities/Offer'
import Label from 'src/components/Label'
import {useRootStore} from 'src/stores/UseStore'

interface OfferManagerCardProps {
  offer: Offer
  employee: ManagerRow
  errorOnSave: boolean
  onChangeHours: (hours: number, minutes: number, offerId: number) => void
  onChangeDays: (isAdd: boolean, offerId: number) => void
  offerHours: ManagerWorkHours
  setOfferHours: (
    offer: ManagerWorkHours | ((prev: ManagerWorkHours) => ManagerWorkHours)
  ) => void
}

const OfferManagerCard = ({
  offer,
  employee,
  errorOnSave,
  onChangeHours,
  onChangeDays,
  offerHours,
  setOfferHours
}: OfferManagerCardProps) => {
  const [error, setError] = useState<boolean>(false)
  const [formatError, setFormatError] = useState<boolean>(false)
  const {filterStore} = useRootStore()

  const bonus = employee.row.bonuses.find(i => i.offerId === offer.id)
  const onBlurHour = (value: string, day: number, date: Date) => {
    const minutes: number = Number(value.split(':')[1])
    const hours = Number(value.split(':')[0])
    const list = offerHours.managerOfferWorkHours
    const index = list[offer.id].findIndex(e => e.date === date)
    if (value.length === 5 && hours <= 24) {
      let totalOTMinutes = minutes + hours * 60
      list[offer.id][index].formtError = false
      if (!list[offer.id].find(e => e.formtError === true)) {
        setFormatError(false)
      }

      if (totalOTMinutes > offerHours.allOTHours[day].totalWorkMinutes) {
        list[offer.id][index].error = true
        setError(true)
      } else {
        list[offer.id][index].error = false
        if (!list[offer.id].find(e => e.error === true)) {
          setError(false)
        }
      }
      list[offer.id][index].otMinuts = totalOTMinutes
    } else {
      setFormatError(true)
      list[offer.id][index].formtError = true
      list[offer.id][index].otMinuts = 0
      list[offer.id][index].error = false
      if (!list[offer.id].find(e => e.error === true)) {
        setError(false)
      }
    }
    list[offer.id][index].status = Status.DB
    setOfferHours(prev => ({...prev, managerOfferWorkHours: list}))
    getSum()
  }

  const getSum = () => {
    let sumMinutes = 0
    if (offerHours.managerOfferWorkHours) {
      offerHours.managerOfferWorkHours[offer.id]?.map(d =>
        Array(d)?.map(eo => {
          return (sumMinutes += eo.otMinuts)
        })
      )
      onChangeHours(Math.floor(sumMinutes / 60), sumMinutes % 60, offer.id)
    }
  }

  return (
    <Card elevation={3}>
      <CardContent>
        <Stack direction="row" spacing={2}>
          <Stack
            direction={
              offer.bonusTypeId === BonusTypeId.NotTakeOff ? 'row' : 'column'
            }
            spacing={2}
          >
            <Stack width={300} direction="row" spacing={0.5} textAlign="center">
              {isHoursType(offer.bonusTypeId) ? (
                <Typography variant="subtitle2">
                  {getHeaderDate(offer)}{' '}
                  {bonus && bonus.bonusStatus === BonusStatus.Overlapping && (
                    <InfoIcon fontSize="inherit" color="warning" />
                  )}
                </Typography>
              ) : (
                <FormControlLabel
                  value={offer.id}
                  control={
                    <Checkbox
                      defaultChecked={
                        employee.row.bonuses.find(
                          o =>
                            o.offerId === offer.id &&
                            o.bonusStatus === BonusStatus.Active
                        ) != null
                      }
                      disabled={
                        filterStore.monthStatus === BonusMonthStatus.Locked
                      }
                      onChange={e =>
                        onChangeDays(e.target.checked, Number(e.target.value))
                      }
                    />
                  }
                  label={
                    <Typography variant="subtitle2">
                      {getHeaderDate(offer)}{' '}
                      {bonus &&
                        bonus.bonusStatus === BonusStatus.Overlapping && (
                          <InfoIcon fontSize="inherit" color="warning" />
                        )}
                    </Typography>
                  }
                  labelPlacement="end"
                />
              )}
            </Stack>
            <Stack
              justifyContent={
                offer.bonusTypeId === BonusTypeId.NotTakeOff ? 'center' : ''
              }
            >
              {offer.bonusTypeId === BonusTypeId.NotTakeOff ? (
                <Typography width={400}>
                  ₪{offer.totalAmount} for taking less than {offer.days}d
                </Typography>
              ) : (
                <>
                  <Typography>
                    {offer.formula && `${offer.formula?.percent}%`}{' '}
                    {offer.formula && offer.totalAmount && '+'}{' '}
                    {offer.totalAmount &&
                      `₪${offer.totalAmount} / ${offer.otHoursInterval}h`}
                    {offer.totalPizzaHours &&
                      `+Pizza / ${offer.totalPizzaHours}h`}
                  </Typography>
                  <Grid
                    container
                    width={300}
                    flexDirection="row"
                    pt={3}
                    spacing={2}
                  >
                    {offer.departments?.map(d => (
                      <Grid key={d.name} marginBottom={0.5} marginLeft={0.5}>
                        <Label
                          key={d.name}
                          fontSize="meduim"
                          variant="ghost"
                          color={'success'}
                          margin={7}
                        >
                          {d.name}
                        </Label>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </Stack>
            {offer.bonusTypeId === BonusTypeId.NotTakeOff && (
              <CuiTitleContent
                title="TEAM"
                width={250}
                content={
                  <Grid container width={270} flexDirection="row" spacing={2}>
                    {offer.departments?.map(d => (
                      <Grid key={d.name} marginBottom={0.5} marginRight={1}>
                        <Label
                          key={d.name}
                          fontSize="meduim"
                          variant="ghost"
                          color={'success'}
                          margin={6}
                        >
                          {d.name}
                        </Label>
                      </Grid>
                    ))}
                  </Grid>
                }
              />
            )}
          </Stack>
          <Stack direction="row" justifyContent="center" textAlign="center">
            {isHoursType(offer.bonusTypeId) && bonus && (
              <Grid
                container
                width={700}
                direction="row"
                alignItems="center"
                spacing={3}
                pl={4}
              >
                {offerHours.managerOfferWorkHours &&
                  offerHours.managerOfferWorkHours[offer.id]?.map(
                    (d, key: number) =>
                      Array(d)?.map(eo => (
                        <Grid pt={1.5} key={eo.offerId} marginRight={2}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              label={
                                new Date(eo.date).getDate() +
                                '.' +
                                (new Date(eo.date).getMonth() + 1)
                              }
                              disabled={
                                filterStore.monthStatus ===
                                BonusMonthStatus.Locked
                              }
                              value={eo.otTime}
                              onChange={() => {}}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  size="small"
                                  sx={{width: 80}}
                                  error={eo.error || eo.formtError}
                                  onBlur={e =>
                                    onBlurHour(
                                      e.target.value,
                                      key +
                                        (compareYearMonth(
                                          offer.fromDate,
                                          filterStore.month
                                        )
                                          ? offer.fromDate.getDate()
                                          : 1),
                                      eo.date
                                    )
                                  }
                                />
                              )}
                              disableOpenPicker
                              ampm={false}
                            />
                          </LocalizationProvider>
                        </Grid>
                      ))
                  )}
              </Grid>
            )}
          </Stack>
          <Typography
            color={errorOnSave ? 'red' : 'black'}
            variant="subtitle1"
            textAlign="center"
          >
            {offerHours.sumOfferOTHours &&
              displayModalOverHoursAndMinutes(
                offerHours.sumOfferOTHours[offer.id]?.totalHours,
                offerHours.sumOfferOTHours[offer.id]?.totalMinutes
              )}
          </Typography>
        </Stack>
        {error && (
          <Stack
            marginTop={2}
            direction="row"
            alignItems="center"
            spacing={0.5}
          >
            <Typography>
              <ErrorIcon fontSize="medium" color="error" />
            </Typography>
            <Typography color={'#7A0C2E'}>
              {`Hours must match or be less than the hours in the Synel`}
            </Typography>
          </Stack>
        )}
        {formatError && (
          <Stack
            marginTop={2}
            direction="row"
            alignItems="center"
            spacing={0.5}
          >
            <Typography>
              <ErrorIcon fontSize="medium" color="error" />
            </Typography>
            <Typography color={'#7A0C2E'}>
              {`Overtime for each day cannot be greater than 24:00 and must be in hh:mm format`}
            </Typography>
          </Stack>
        )}
      </CardContent>
    </Card>
  )
}

export default OfferManagerCard
