import TextField from '@mui/material/TextField'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {DatePicker} from '@mui/x-date-pickers'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import {Box, InputAdornment} from '@mui/material'
import config from 'src/config'
import BonusMonth, {BonusMonthStatus} from 'src/entities/BonusMonth'
import {compareYearMonth} from 'src/utils/dateHelper'

interface CuiMonthPickerProps {
  date: Date | null
  setDate: (value: Date) => void
  bonusMonthList?: BonusMonth[] | undefined
  showIcon?: boolean
  setMonthStatus?: (val: BonusMonthStatus | undefined) => void
  loading?: boolean
  width?: number
}

export default function CuiMonthPicker({
  date,
  setDate,
  bonusMonthList,
  showIcon = true,
  setMonthStatus,
  loading,
  width
}: CuiMonthPickerProps) {
  const disableDates = (date: Date) => {
    if (compareYearMonth(date, new Date())) return false
    return !bonusMonthList?.find(b => {
      return compareYearMonth(b.month, date)
    })
  }

  const onChangeDate = (val: Date | null) => {
    if (val) {
      if (setMonthStatus !== undefined) setMonthStatus(undefined)
      setDate(val)
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disabled={loading}
        shouldDisableMonth={disableDates}
        views={['year', 'month']}
        label="Month"
        openTo="month"
        minDate={new Date(config.minDate)}
        maxDate={new Date()}
        value={date}
        onChange={onChangeDate}
        InputProps={
          bonusMonthList?.find(b =>
            compareYearMonth(b.month, date || new Date())
          )?.status === BonusMonthStatus.Locked && showIcon
            ? {
                startAdornment: (
                  <InputAdornment position="start">
                    <Box color="error.light">
                      <LockOutlinedIcon color="inherit" />
                    </Box>
                  </InputAdornment>
                )
              }
            : undefined
        }
        inputFormat="MM/yyyy"
        renderInput={params => (
          <TextField sx={{minWidth: 200, width: width || 200}} {...params} />
        )}
      />
    </LocalizationProvider>
  )
}
