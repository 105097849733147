import {GridFilterModel, GridLinkOperator} from '@mui/x-data-grid-pro'
import {makeAutoObservable} from 'mobx'
import {SearchManagerEmployee} from 'src/entities/Employee'
import {BonusMonthStatus} from 'src/entities/BonusMonth'

export default class FilterStore {
  private _filterModel = {
    items: [],
    linkOperator: GridLinkOperator.And
  } as GridFilterModel

  private _search = {} as SearchManagerEmployee

  private _month = new Date()

  private _monthStatus?: BonusMonthStatus

  constructor() {
    makeAutoObservable(this)
  }

  public get filterModel(): GridFilterModel {
    return this._filterModel
  }

  public set filterModel(value: GridFilterModel) {
    this._filterModel = value
  }

  public get search(): SearchManagerEmployee {
    return this._search
  }

  public set search(value: SearchManagerEmployee) {
    this._search = value
  }

  public get month(): Date {
    return this._month
  }

  public set month(value: Date) {
    this._month = value
  }

  public get monthStatus(): BonusMonthStatus | undefined {
    return this._monthStatus
  }

  public set monthStatus(value: BonusMonthStatus | undefined) {
    this._monthStatus = value
  }

  reset() {
    this._filterModel = {
      items: [],
      linkOperator: GridLinkOperator.And
    } as GridFilterModel
    this._search = {} as SearchManagerEmployee
    this._month = new Date()
    this._monthStatus = undefined
  }
}
